import { FaArrowRight } from "react-icons/fa6";
import { Helmet } from "react-helmet";
import "../../styles/Products/Product.css";
import UniqueSection from "../Home/UniqueSection";
import grouplogo from "../../assets/products/Group 42.webp";

// import banner from '../../assets/picture/How Minsky AI2.png';
import circuitImg from "../../assets/picture/circuit.webp";
import { TypingAnimation } from "../AboutUs/AboutUsMain";

const Products = () => {
  return (
    <div style={{ marginTop: "14rem" }}>
      <Helmet>
        <title>
          Cutting-edge AI Solutions for Tomorrow's Challenges: Neurobridge
          Minsky
        </title>
        <meta
          name="description"
          content="Discover Cutting-edge AI Solutions for Tomorrow's Challenges With Neurobridge Minsky. Specializing in Generative AI Chatbots, We Are Paving the Way for the Future of Artificial Intelligence. Visit Now!"
        />
      </Helmet>
      {/* <span className='pricing-top-heading'>NeuroBridge Solutions 2024</span>
      <div className='pricing-top-section'>
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`img${index + 1}`}
            className={
              isVisible
                ? `pricing-img${index + 1} fade-in`
                : `pricing-img${index + 1}`
            }
          />
        ))}
        <p className='sub-heading'>
          Solutions for All of your <br /> Comprehensive
          <span>
            {' '}
            Artificial <br />
            Intelligence
          </span>{' '}
          Topics
        </p>
        <p className='pricing-sub-sub-heading'>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusm.
        </p>
      </div> */}
      {/* <div className='contact-TTS'>
        <FaCalendarDays />
        View TTS Plan
      </div> */}
      <UniqueSection />

      <div className="group-section">
        <img src={grouplogo} alt="group-section" />
        <div className="group-section-text">
          <div className="group-heading">
            <div className="left-line"></div>
            <div className="group-section-heading">
              <TypingAnimation text="Embrace the"></TypingAnimation>{" "}
              <span>Future</span>
            </div>
            <div className="right-line"></div>
          </div>
          <p className="text-paragraph">
            <TypingAnimation
              text="Venture deeper into the AI realm with models. Venture deeper into
            the AI realm"
            ></TypingAnimation>
          </p>
        </div>
      </div>

      {/* <div className='product-section-main-div'>
        <div className='empty-placeholder'>
          
        </div>
        <div className='product-main-content'>
          <Zoom damping={0.1}>
            <p className='sub-heading'>
              <span>TAILORED SOLUTIONS</span> FOR YOUR SYSTEM
            </p>
          </Zoom>
          <Zoom damping={0.4}>
            <p className='main-heading'>
              Technology
              <br />
              Explained for <span>TTS</span> <br />
              Service
            </p>
          </Zoom>
          <Zoom damping={0.6}>
            <p className='text-paragraph'>
              Venture deeper into the AI realm with models. Venture deeper into
              the AI realm.
            </p>
          </Zoom>

          <Zoom damping={0.8}>
            <div className='viewtts-button'>
              <FaCalendarDays />
              View TTS Plan
            </div>
          </Zoom>
        </div>
      </div> */}
      <div className="product-circuit-container">
        <div className="product-circuit-background-blue">
          <img src={circuitImg} alt="circuit" />
          <div className="product-circuit-text">
            <div className="heading">
              AI Search and
              <br /> Generative Answers
            </div>
            <div className="paragraph">
              Imagine a search engine that thinks like a human. Minsky AI's
              <br />
              search understands context, delivering meaningful answers
              <br /> from your unstructured data, providing invaluable insights
              at your
              <br />
              fingertips.
            </div>
            <button className="blue-button">
              Explore More <FaArrowRight />
            </button>
          </div>
        </div>
      </div>
      <div className="parent-container">
        <div className="container">
          <div className="red-container">
            <div className="heading">AI Summarisation</div>
            <div className="paragraph">
              Extracting vital information from vast datasets is effortless with
              Minsky AI's Summarisation feature. Get concise, actionable
              insights to make informed decisions quickly and efficiently.
            </div>
            <button className="red-button">
              Explore More <FaArrowRight />
            </button>
          </div>
          <div className="blue-container">
            <div className="heading">Personalised User Prompts</div>
            <div className="paragraph">
              Customise your AI experience with personalised prompts, defining
              the conversation to suit your organisation's unique needs. Minsky
              AI responds with precision, ensuring seamless interaction.
            </div>
            <button className="blue-button">
              Explore More <FaArrowRight />
            </button>
          </div>
        </div>
      </div>
      <div className="product-circuit-container">
        <div className="product-circuit-background-red">
          <img src={circuitImg} alt="circuit" />
          <div className="product-circuit-text">
            <div className="heading">AI Search Copilot</div>
            <div className="paragraph">
              Navigate the complexities of data management with ease using
              <br />
              Minsky AI's Search Copilot. From customer support to
              troubleshooting, <br />
              this virtual assistant simplifies tasks, ensuring smooth <br />{" "}
              operations.
            </div>
            <button className="red-button">
              Explore More <FaArrowRight />
            </button>
          </div>
        </div>
      </div>
      <div className="parent-container">
        <div className="container" style={{ flexDirection: "row-reverse" }}>
          <div className="red-container">
            <div className="heading">Generated Q&A </div>
            <div className="paragraph">
              Transform your data into knowledge goldmines with Minsky AI's
              Generated Q&A. Automate answers and streamline searching, saving
              valuable time and resources.
            </div>
            <button className="red-button">
              Explore More <FaArrowRight />
            </button>
          </div>
          <div className="blue-container">
            <div className="heading">Prompt Lab for LLM Validation</div>
            <div className="paragraph">
              Fine-tune your AI experience effortlessly with Minsky AI's Prompt
              Lab. Experiment with prompts and models, ensuring your AI aligns
              perfectly with your organisation's goals and objectives.
            </div>
            <button className="blue-button">
              Explore More <FaArrowRight />
            </button>
          </div>
        </div>
      </div>
      <div className="product-circuit-container">
        <div className="product-circuit-background-red">
          <img src={circuitImg} alt="circuit" />
          <div className="product-circuit-text">
            <div className="heading">RAG-as-a-Service</div>
            <div className="paragraph">
              Experience the seamless integration of retrieval and generative
              <br />
              techniques with RAG-as-a-Service. Unravel complex data puzzles
              <br />
              effortlessly, gaining actionable insights with unparalleled ease.
            </div>
            <button className="red-button">
              Explore More <FaArrowRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Products;
