import React, { useState } from "react";
import cardIcon from "../../assets/icons/minskyCardIcon.png";
import "../../styles/Home/components/MinskyContainer.css";
import { FaChevronDown } from "react-icons/fa";
import img1 from "../../assets/logos/First Image.webp";
import img2 from "../../assets/logos/Second Page.webp";
import img3 from "../../assets/logos/Third image.webp";
import img4 from "../../assets/logos/Fourth Image.webp";

const MinskyContainer = () => {
  const [expandedIndex, setExpandedIndex] = useState({});
  const [currentImage, setCurrentImage] = useState(img1);

  const questions = [
    "RAG as a Service",
    "Customizable AI Assistants",
    "Advanced Generative Search",
    "Enhanced Decision Making",
  ];
  const answers = [
    "We transform your unstructured data into an AI-powered Knowledge Base. This process makes your data manageable and actionable.",
    "Our platform offers customizable AI assistants to meet your specific needs for example coding, legal, HR, or marketing. Create and tailor your own bots for targeted insights and support, enhancing your business strategy with precision.",
    "Our generative search technology quickly delivers the most relevant answers, ensuring users gain efficient access to the information they need.",
    "By streamlining data processes and improving operational efficiency, our advanced analytics enable better decision-making. We help you navigate and utilize your data more effectively, driving your business forward.",
  ];
  const images = [img1, img2, img3, img4];
  const subHeading =
    "Minsky AI streamlines data indexing processes across various sectors using AI and natural language processing, offering quick results and valuable insights without manual searching.";

  const toggleExpand = (index) => {
    setExpandedIndex((prevIndex) => {
      return prevIndex === index ? null : index;
    });
    if (expandedIndex === index) {
      setCurrentImage(images[0]); // Set default image when closing
    } else {
      setCurrentImage(images[index]); // Update current image
    }
  };

  return (
    <div className="minsky-container">
      <p className="heading">
        Automated Insights
        <br /> Integrating Simplifying Data <br />
        Indexing with Minsky <span>AI</span>
      </p>
      <div className="row-container">
        <div className="stats-div">
          <p className="sub-heading">{subHeading}</p>
          {questions.map((item, index) => (
            <React.Fragment key={index}>
              <div style={{ display: "block" }}>
                <div
                  className={`text-color2 ${
                    expandedIndex === index ? "active" : ""
                  }`}
                  onClick={() => toggleExpand(index)}
                >
                  <div
                    className="gapImage2"
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    {/* <img alt="" src={imageService[index]} />*/}
                    <div style={{ display: "flex", alignItems: "flex-start" }}>
                      <img alt="" src={cardIcon} />
                      <h1>{item}</h1>
                    </div>
                    <h1
                      className="downArrow"
                      style={{ alignItems: "flex-end" }}
                    >
                      <FaChevronDown style={{ color: "#ffffffa1" }} />
                    </h1>
                  </div>
                </div>

                {expandedIndex === index && (
                  <div className="text-color">
                    {/* Content when the box is expanded */}
                    <p>{answers[index]}</p>
                  </div>
                )}
              </div>
            </React.Fragment>
          ))}
        </div>
        <div className="right-empty-div">
          <img
            src={currentImage}
            alt="banner"
            className="right-empty-div-image"
          />
        </div>
      </div>
    </div>
  );
};

export default MinskyContainer;
