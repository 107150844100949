import circuitImg from "../../assets/video/GIF neurobridge.gif";
import "../../styles/Home/components/Circuit.css";
const Circuit = () => {
  const styles = {
    div: {
      width: "100%",

      boxSizing: "border-box",
    },
  };

  return (
    <div style={styles.div} className="video-div">
      <img src={circuitImg} alt="circuit" className="circuit-image" />
      {/* <video autoPlay loop muted style={styles.video}>
        <source src={circuitVideo} type='video/mp4' />
        Your browser does not support the video tag.
      </video> */}
    </div>
  );
};

export default Circuit;
