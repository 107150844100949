import React from 'react';
import ticketIcon from '../../assets/icons/ticketIcon.svg';
import ScheduleBtn from '../../globalComponents/scheduleBtn/ScheduleBtn';
import '../../styles/Home/components/ScheduleDemo.css';

const ScheduleDemo = () => {
  const text =
    "Come experience innovation up close with Neurobridge Tech! Schedule a live demonstration to see how it's changing the game for productivity, creativity, and making customers happy.";

  return (
    <div className='schedule-demo-main-div'>
      <div className='schedule-main-content'>
        <img src={ticketIcon} className='ticket-icon' alt='ticket' />

        <p className='main-title'>
          Unlock Your Potential
          {/* <span>Cutting Edge</span> Platform */}
        </p>

        <p className='main-text'>{text}</p>

        <ScheduleBtn />
      </div>
    </div>
  );
};

export default ScheduleDemo;
