import React from "react";
import { Zoom } from "react-awesome-reveal";
import "../../styles/Home/components/Testimonials.css";
import blurry from "../../assets/picture/blurry.webp";
import john from "../../assets/picture/john.webp";
import jane from "../../assets/picture/janes.webp";
import { FaPlay } from "react-icons/fa6";
const Testimonials = () => {
  const testimonialsData = [
    {
      name: "Blurry Meal",
      handle: "@blurrymeal",
      image: blurry,
      text: "NeuroBridge has revolutionized the way our team handles AI-driven tasks. The Minsky platform is incredibly intuitive, and Chanakya AI, with its capabilities similar to ChatGPT, has significantly improved our productivity. It's like having a genius assistant who’s always ready to help!",
    },
    {
      name: "John Doe",
      handle: "@johnhandle",
      image: john,
      text: "NeuroBridge's Minsky platform is a game-changer in the AI space. Chanakya AI, their flagship product, has been instrumental in automating complex tasks and providing intelligent insights. It's one of the most reliable AI tools we've used in our projects.",
    },
    {
      name: "Jane Smith",
      handle: "@janesmith",
      image: jane,
      text: "Integrating NeuroBridge's Minsky platform into our workflow has been seamless and highly effective. Chanakya AI is on par with the best in the industry, like ChatGPT, offering unparalleled support in generating content and analyzing data. Highly recommended for any business looking to leverage AI!",
    },
  ];

  const TestimonialsBox = ({ name, handle, text, image }) => (
    <div className="testimonials">
      <div className="testimonials-header">
        <img src={image} alt="img" />
        <div>
          <p
            style={{
              fontSize: "22px",
              fontFamily: "Helvetica Now Display",
              fontWeight: 400,
            }}
          >
            {name}
          </p>
          <p
            style={{
              fontSize: "16px",
              fontFamily: "Helvetica Now Display",
              fontWeight: 400,
              textAlign: "left",
              color: "rgba(255, 255, 255, 0.52)",
            }}
          >
            {handle}
          </p>
        </div>
      </div>
      <div className="testimonials-text">{text}</div>
      <div className="testimonials-audio">
        <div
          style={{
            width: "3rem",
            height: "3rem",
            borderRadius: "50%",
            background: "#E25858",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
        >
          <FaPlay style={{ fontSize: "1.5rem" }} />
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="180"
          height="30"
          viewBox="0 0 213 35"
          fill="none"
        >
          <g opacity="0.6">
            <rect y="9" width="5" height="18" rx="2.5" fill="white" />
            <rect x="8" y="4" width="5" height="27" rx="2.5" fill="white" />
            <rect x="16" width="5" height="35" rx="2.5" fill="white" />
            <rect x="24" y="3" width="5" height="29" rx="2.5" fill="white" />
            <rect x="32" y="6" width="5" height="23" rx="2.5" fill="white" />
            <rect x="40" y="3" width="5" height="29" rx="2.5" fill="white" />
            <rect x="48" y="8" width="5" height="20" rx="2.5" fill="white" />
            <rect x="56" y="2" width="5" height="31" rx="2.5" fill="white" />
            <rect x="64" y="9" width="5" height="18" rx="2.5" fill="white" />
            <rect x="72" y="4" width="5" height="27" rx="2.5" fill="white" />
            <rect x="80" width="5" height="35" rx="2.5" fill="white" />
            <rect x="88" y="3" width="5" height="29" rx="2.5" fill="white" />
            <rect x="96" y="6" width="5" height="23" rx="2.5" fill="white" />
            <rect x="104" y="3" width="5" height="29" rx="2.5" fill="white" />
            <rect x="112" y="8" width="5" height="20" rx="2.5" fill="white" />
            <rect x="120" y="2" width="5" height="31" rx="2.5" fill="white" />
            <rect x="128" y="9" width="5" height="18" rx="2.5" fill="white" />
            <rect x="136" y="4" width="5" height="27" rx="2.5" fill="white" />
            <rect x="144" width="5" height="35" rx="2.5" fill="white" />
            <rect x="152" y="3" width="5" height="29" rx="2.5" fill="white" />
            <rect x="160" y="6" width="5" height="23" rx="2.5" fill="white" />
            <rect x="168" y="3" width="5" height="29" rx="2.5" fill="white" />
            <rect x="176" y="8" width="5" height="20" rx="2.5" fill="white" />
            <rect x="184" y="2" width="5" height="31" rx="2.5" fill="white" />
            <rect x="192" y="9" width="5" height="18" rx="2.5" fill="white" />
            <rect x="200" y="4" width="5" height="27" rx="2.5" fill="white" />
            <rect x="208" width="5" height="35" rx="2.5" fill="white" />
          </g>
        </svg>
      </div>
    </div>
  );

  return (
    <div className="additional-section-main-div">
      <div className="additional-main-content">
        <Zoom damping={0.1}>
          <p className="sub-heading">
            <span>TESTIMONIALS</span>
          </p>
        </Zoom>
        <Zoom damping={0.4}>
          <p className="main-heading">
            Testimonials Section for <span>NeuroBridge </span>
            Platforms
          </p>
        </Zoom>
      </div>
      <div
        className="crousel-testiomonials"
        style={{
          marginTop: "-4rem",
          display: "grid",
          gridTemplateColumns: "repeat(3,1fr)",
          justifyContent: "center",
          alignItems: "center",
          gap: "1.4rem",
        }}
      >
        {testimonialsData.map((testimonial, index) => (
          <TestimonialsBox key={index} {...testimonial} />
        ))}
      </div>
    </div>
  );
};

export default Testimonials;
