import React from "react";
import "../../styles/Home/Pricing.css";
import blog from "../../assets/picture/the-impact.webp";
import popular1 from "../../assets/picture/popular-blog-1.webp";
import popular2 from "../../assets/picture/popular-blog-2.webp";
import popular3 from "../../assets/picture/popular-blog-3.webp";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin, FaXTwitter } from "react-icons/fa6";
import { Helmet } from "react-helmet";
const TheImpact = () => {
  const shareUrl =
    "https://neurobridge.tech/blogs/the-impact-of-internal-bots-on-business";
  const handleShare = (platform) => {
    switch (platform) {
      case "instagram":
        window.open(`https://www.instagram.com/?url=${shareUrl}`);
        break;
      case "linkedin":
        window.open(`https://www.linkedin.com/shareArticle?url=${shareUrl}`);
        break;
      case "twitter":
        window.open(`https://twitter.com/intent/tweet?url=${shareUrl}`);
        break;
      default:
        break;
    }
  };
  const TopicButtons = [
    { id: 0, name: "AI" },
    { id: 1, name: "Generative AI" },
    { id: 2, name: "Filmmaking" },
    { id: 3, name: "Star Wars" },
  ];

  const line = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="2"
      viewBox="0 0 100 2"
      fill="none"
    >
      <path
        d="M231 1.00002L1 1"
        stroke="#9B58E2"
        stroke-width="25"
        stroke-linecap="round"
      />
    </svg>
  );
  const cardsPopular = [
    {
      image: popular1,
      heading: "April 1, 2024",
      subheading:
        "Discovering The Versatility Of Minsky Ai In Real-World Applications",
      linkTo:
        "/blogs/discovering-the-versatility-of-minsky-ai-in-real-world-applications",
    },
    {
      image: popular2,
      heading: "April 18, 2024",
      subheading:
        "Finding Your Perfect AI Audio Generator: A Step-by-Step Guide",
      linkTo:
        "/blogs/finding-your-perfect-ai-audio-generator-a-step-by-step-guide",
    },
    {
      image: popular3,
      heading: "April 15, 2024",
      subheading:
        "Enhancing Customer Experiences: The Role of AI-Based Chatbots",
      linkTo:
        "/blogs/enhancing-customer-experiences-the-role-of-ai-based-chatbots",
    },
  ];

  const Card = ({ image, heading, subheading, linkTo }) => (
    <div className="card">
      <div className="image-div">
        <a href={linkTo}>
          <img className="img" src={image} alt={heading} />
        </a>
      </div>
      <div className="card-content">
        <h1 className="tailored" style={{ color: "#9B58E2", fontSize: "15px" }}>
          {heading}
          <h1 className="subheading al-references-heading">
            {subheading}
            <br />

            <p1 style={{ cursor: "pointer" }}>
              <a
                style={{ color: "#9B58E2", textDecoration: "none" }}
                href={linkTo}
              >
                Read More...
              </a>
            </p1>
          </h1>
        </h1>
      </div>
    </div>
  );
  return (
    <div className="innerBlog">
      <Helmet>
        <title>
          The Impact of Internal Bots on Business With Generative AI Chatbot:
          Neurobridge Minsky
        </title>
        <meta
          name="description"
          content="Discover How Neurobridge Minsky's Generative AI Chatbot Is Revolutionizing Internal Communication and Increasing Productivity in Businesses. Explore the Impact of Internal Bots on Business Today. Visit Now!"
        />
      </Helmet>
      <div className="blog-div">
        <div className="blog-image">
          <img className="blog-img" src={blog} alt="" />
        </div>
        <div className="text-div-blog">
          <h2 className="lorem-text">Date</h2>
          <h2
            className="top-heading-blog"
            style={{ opacity: "0.6", color: "#FFF" }}
          >
            March 31, 2024
          </h2>
          {line}
          <h2 className="lorem-text">Keywords</h2>
          <h2 className="topic">
            {TopicButtons.map((d, index) => (
              <button key={index}>{d.name}</button>
            ))}
          </h2>
          {line}
          <h2 className="lorem-text">Share</h2>
          <div className="shareLogo">
            <FaInstagram
              style={{ color: "#fff", cursor: "pointer" }}
              onClick={() => handleShare("instagram")}
            />
            <FaXTwitter
              style={{ color: "#FFF", cursor: "pointer" }}
              onClick={() => handleShare("twitter")}
            />
            <FaLinkedin
              style={{ color: "#FFF", cursor: "pointer" }}
              onClick={() => handleShare("linkedin")}
            />
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="blog-div-content">
        <h2 className="ai-references">
          Boosting Productivity: The Impact Of Internal Bots On Business
        </h2>
        <h1 className="text-lorem">
          Organisations are always looking for creative ways to improve
          productivity and streamline processes in the fast-paced, high-stakes
          business environment of today. Integrating internal bots into company
          operations is one such approach that is becoming more and more
          popular. These machine learning- and AI-powered algorithm-driven
          digital assistants have the potential to completely transform how work
          is done in organisations, which will ultimately result in large
          productivity increases.
          <br />
          <br />
          This blog post aims to look into the significant influence internal
          bots might have on companies of all sizes and in a range of
          industries. Internal bots allow up valuable personnel to work on more
          strategic projects, innovative problem-solving, and high-value tasks
          by reducing repetitive and laborious chores. These bots can do a wide
          range of operations with speed and precision, including data entry,
          inquiries from customers, scheduling, and creating reports. This
          speeds up workflows and lowers operating expenses.
          <br />
          <br />
          <span>Benefits Of Internal Chatbots In Businesses</span>
          <br />
          <br />
          <span>1. 24*7 Availability</span>
          <br />
          <br />
          Regardless of time regions or working hours, internal chatbots are
          always available to employees, giving them rapid access to help and
          information. This lowers wait times for replies or resolutions,
          ensuring continuous support and improving overall productivity.
          <br />
          <br />
          <span>2. Time Savings</span>
          <br />
          <br />
          Internal chatbots save staff important time that may be devoted to
          more strategic or valuable duties by automating mundane tasks like
          responding to frequently asked queries, granting access to resources
          within the organisation, or setting up meetings. As a result, the
          procedures for making decisions become more efficient and quick.
          <br />
          <br />
          <span>3. Reliable And Accurate Responses</span>
          <br />
          <br />
          Internal chatbots provide reliable and accurate answers to questions
          or requests, removing the possibility of human error and guaranteeing
          uniform communication throughout the company. This improves the
          calibre of exchanges and reduces miscommunication or inconsistent
          information sharing.
          <br />
          <br />
          <span>4. Cost Reduction</span>
          <br />
          <br />
          Internal chatbots assist businesses in cutting labour, training, and
          overhead costs by eliminating repetitive processes and minimising the
          demand for human oversight. The deployment of chatbots also leads to
          reduced procedures and enhanced productivity, which lowers overall
          costs.
          <br />
          <br />
          <span>5. Improved Teamwork And Communication</span>
          <br />
          <br />
          By giving workers immediate access to pertinent data, files, or
          teammates, internal chatbots promote smooth teamwork and
          communication. This promotes a more cohesive and effective work
          environment, particularly in distant or dispersed teams.
          <br />
          <br />
          <span>Conclusion</span>
          <br />
          <br />
          To sum up, the incorporation of internal bots into corporate processes
          signifies a fundamental change in the way companies handle increasing
          productivity and optimising operations. We've learned from our studies
          how revolutionary internal bots may be for companies of all sizes and
          in a wide range of sectors.
          <br />
          <br />
          The use of AI and machine learning-driven bots provide a multitude of
          advantages that lead to notable increases in productivity. By means of
          automating monotonous duties, offering prompt support, and
          streamlining processes, these digital assistants enable staff members
          to concentrate on high-value and essential responsibilities, thus
          promoting excellence in operations.
          <br />
        </h1>
        <h1 className="blog-card-heading">Similar Blogs</h1>
        <div className="blog-card-div">
          {cardsPopular.map((card, index) => (
            <Card key={index} {...card} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default TheImpact;
