import React from 'react';
import fadedLine from '../../assets/extras/fadedLine.webp';
import img1 from '../../assets/icons/Mask group.png';
import img2 from '../../assets/icons/Mask group (2).png';
import img3 from '../../assets/icons/Mask group (1).png';
import img4 from '../../assets/icons/Group 38.png';

import logoCentre from '../../assets/logos/minskyCentre.webp';
// import gridBg from "../../assets/background/gridTitle.png"
import '../../styles/Home/components/OurBenefits.css';

const OurBenefits = () => {
  const images = [img4, img1, img3, img2];
  const linearBackground = [
    'linear-gradient(to left, #221E25, #1B1A1A)',
    '',
    '',
    'linear-gradient(to right, #221E25, #1B1A1A)',
  ];
  const subTitle =
    'Venture deeper into the AI realm with models. Venture deeper into the AI realm';
  const cardData = [
    {
      title: 'Precision in Every Interaction',
      para: 'RAG Models ensure customised engagements that align seamlessly with your objectives',
    },
    {
      title: 'Real-Time Knowledge Access',
      para: 'RAG Models provide up-to-date information for each response',
    },
    {
      title: 'Resource-Efficient Customisation',
      para: 'Effortlessly integrate custom data models for efficiency without complexity',
    },
    {
      title: 'Versatility Beyond Boundaries',
      para: 'RAG Models adapt universally across industries',
    },
  ];

  return (
    <div className='benefits-main-div'>
      <div className='main-title'>
        {/* <img 
                src={gridBg} 
                className='grid-bg' 
                alt='grid-bg'
            /> */}

        <img src={fadedLine} className='faded-line' alt='faded-line' />
        <p className='main-heading'>
          Why <span>RAG?</span>
        </p>
        <img src={fadedLine} className='faded-line-flipped' alt='faded-line' />
      </div>

      <p className='sub-title'>{subTitle}</p>

      <div className='card-main-container'>
        <img
          src={logoCentre}
          className='centre-logo-div'
          alt='centre-logo-div'
        />

        {cardData.map((item, index) => (
          <div
            key={index}
            className='benefits-card-main'
            style={{
              background: linearBackground[index],
            }}
          >
            <img src={images[index]} className='edit-icon' alt='edit-icon' />
            <p className='card-title'>{item.title}</p>
            <p className='card-para'>{item.para}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default OurBenefits;
