import React, { useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import MainLogo from '../mainLogo/MainLogo';
import {
  FaChevronRight,
  FaInstagram,
  FaLinkedin,
  FaLocationDot,
  FaTwitter,
} from 'react-icons/fa6';
import '../../styles/globalComponents/Footer/Footer.css';
import { SiGmail } from 'react-icons/si';
import { TiSocialInstagram } from 'react-icons/ti';

const Footer = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [email, setEmail] = useState('');
  useLayoutEffect(() => {
    const checkScreenWidth = () => {
      setIsMobile(window.innerWidth <= 468);
    };
    checkScreenWidth();
    window.addEventListener('resize', checkScreenWidth); // Event listener for resize
    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []);

  const para = 'Venture deeper into the AI realm with models.';
  const footerBtns = [
    {
      title: 'menu',
      items: [
        { name: 'home', linkTo: '/' },
        { name: 'Solutions', linkTo: '/solutions' },
        { name: 'Pricing', linkTo: '/pricing' },
        { name: 'Blog', linkTo: '/blogs' },
        { name: 'Contact Us', linkTo: '/contact' },
      ],
    },
    {
      title: 'blogs',
      items: [
        { name: 'Recent', linkTo: '/blogs' },
        { name: 'popular', linkTo: '/blogs' },
        { name: 'trending', linkTo: '/blogs' },
      ],
    },
    {
      title: 'contact',
      items: [
        {
          name: isMobile ? (
            <FaLocationDot className='fotter-icon' />
          ) : (
            'Location'
          ),
          linkTo: '/',
        },
        {
          name: isMobile ? <SiGmail className='fotter-icon' /> : 'Mail',
          linkTo: '/',
        },
        {
          name: isMobile ? (
            <TiSocialInstagram className='fotter-icon' />
          ) : (
            'Social'
          ),
          linkTo: '/',
        },
      ],
    },
  ];
  const handleClick = () => {
    setShowEmailInput(true);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you can submit the email to your backend or perform any other action
    console.log('Submitted email:', email);
    // Clear the email input and hide it
    setEmail('');
    setShowEmailInput(false);
  };
  return (
    <div className='footer-main-div'>
      <div className='footer-main-content'>
        <div className='logo-container'>
          <MainLogo />
        </div>
        <p className='footer-main-para'>{para}</p>

        {showEmailInput ? (
          <form
            onSubmit={handleSubmit}
            className='form'
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <div
              className='email-form-input'
              style={{
                fontSize: '1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <input
                type='email'
                value={email}
                onChange={handleEmailChange}
                placeholder='Enter your email'
                style={{
                  padding: '0.7rem',
                }}
              />
            </div>
            <button
              className='email-form-button'
              type='submit'
              style={{
                fontSize: '1rem',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                margin: '0.4rem',
                position: 'relative',
                top: '3px',
              }}
            >
              Submit
            </button>
          </form>
        ) : (
          <button onClick={handleClick} className='newsletter-btn'>
            Subscribe To Newsletter
            <FaChevronRight />
          </button>
        )}
      </div>

      <div className='footer-buttons'>
        {footerBtns.map((items, index) => (
          <div key={index} className='footer-columns'>
            <p className='footer-list-title'>{items.title}</p>
            {items.items.map((item, id) => (
              <Link key={id} to={item.linkTo} className='footer-links'>
                {item.name}
              </Link>
            ))}
          </div>
        ))}
        <div className='footer-columns'>
          <p className='footer-list-title'>Socials</p>

          <div
            className='footer-links'
            style={{ display: 'flex', gap: '0.5rem' }}
          >
            <FaInstagram className='fotter-icon' />
            <FaLinkedin className='fotter-icon' />
            <FaTwitter className='fotter-icon' />
            <SiGmail className='fotter-icon' />
          </div>
        </div>
      </div>

      <div className='bottom-bar-container'>
        <p className='bottom-bar-text'>
          <br />
          Copyright ©2024 Neurobridge
          <br />
          <br />
        </p>
      </div>
    </div>
  );
};

export default Footer;
