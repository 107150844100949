import React, { useState, useEffect, useRef } from "react";
import blackBlur from "../../assets/background/heroBgGroup.webp";
import starsIcon from "../../assets/icons/stars.png";
import banner1 from "../../assets/banner/chanakya_banner1.webp";
import banner2 from "../../assets/banner/chanakya_banner2.webp";
import banner3 from "../../assets/banner/chanakya_banner3.webp";
import "../../styles/Home/components/Hero.css";
import playButton from "../../assets/banner/google-play-svgrepo-com.svg";
import { Zoom } from "react-awesome-reveal";
import { useMobile } from "../../globalComponents/isMobileContext";
import axios from "axios";

const useBannerInterval = (banners, interval, setCurrentBannerIndex) => {
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentBannerIndex((prevIndex) => (prevIndex + 1) % banners.length);
    }, interval);

    return () => clearInterval(timer);
  }, [banners, interval, setCurrentBannerIndex]);
};

const Hero = () => {
  const { isMobile } = useMobile();
  const subText =
    "Transform customer service, enhancing customer experience and satisfaction.";
  const paraText =
    "Neurobridge Minsky provides a trusted and secured Gen AI platform where organizations can use their existing data and create a powerful knowledge base. Our customized assistants allows you to leverage this knowledge and get insights, support and answers needed to enhance decision-making and streamlining business processes.";
  const prompts = [
    "How can Minsky help me?",
    "What is RAG as a Service?",
    "What LLM’s Can i use?",
    "How can I integrate this in my business?",
  ];

  const [message, setMessage] = useState("");
  const [reciveMsg, setReciveMsg] = useState([]);
  const [currentBannerIndex, setCurrentBannerIndex] = useState(0);
  const bannerContainerRef = useRef(null);

  const handlePopup = async () => {
    if (!message.trim()) return;
    try {
      const requestBody = {
        model: "MISTRAL_8X7B",
        query: `<s>[INST] You are Minsky an AI assistant made by Neurobridge. Your Task is to answer users query. Respond with IDK if harmful or dangerous things are asked. \n User Query: ${message} [/INST]`,
        max_tokens: 200,
        stop: "</s>",
        temperature: 0.7,
        top_p: 0.7,
        top_k: 50,
        repetition_penalty: 1,
      };

      const response = await axios.post(
        "http://localhost:3001/api/generate-request",
        requestBody
      );

      const responseData = response.data;
      setMessage("");
      setReciveMsg(responseData.response[0].text);
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleChange = (e) => {
    setMessage(e.target.value);
  };

  const handlePromptClick = (promptText) => {
    setMessage(promptText);
  };

  const banners = [banner1, banner2, banner3];
  useBannerInterval(banners, 3000, setCurrentBannerIndex);

  const handleScroll = () => {
    if (bannerContainerRef.current) {
      const scrollLeft = bannerContainerRef.current.scrollLeft;
      const containerWidth = bannerContainerRef.current.clientWidth;
      const newIndex = Math.round(scrollLeft / containerWidth);
      setCurrentBannerIndex(newIndex);
    }
  };

  useEffect(() => {
    const currentBannerContainer = bannerContainerRef.current;
    if (currentBannerContainer) {
      currentBannerContainer.addEventListener("scroll", handleScroll);
      return () => {
        currentBannerContainer.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  const handleChanakya = () => {
    window.location.href = "https://aichanakya.in/";
  };

  const handleChanakyaAPK = () => {
    window.location.href =
      "https://play.google.com/store/apps/details?id=tech.neurobridge.chanakya";
  };

  return (
    <>
      <div
        className="banner-container"
        onClick={handleChanakya}
        ref={bannerContainerRef}
      >
        {banners.map((banner, index) => (
          <img
            key={index}
            src={banner}
            className="banner"
            alt=""
            style={{
              display: index === currentBannerIndex ? "block" : "none",
            }}
          />
        ))}
      </div>

      <div
        className="google-play-button"
        onClick={handleChanakyaAPK}
        style={{
          left: currentBannerIndex === 1 ? "auto" : "2rem",
          right: currentBannerIndex === 1 ? "2rem" : "auto",
        }}
      >
        <div className="custom-style">
          <img
            src={playButton}
            style={{ width: isMobile ? "20px" : "30px", alignSelf: "center" }}
            alt="Google Play"
          />
          <div className="button-text-container">
            <span className="button-title">GET IT ON</span>
            <span className="button-store-name">Google Play</span>
          </div>
        </div>
      </div>

      <div className="banner-dots">
        {banners.map((_, index) => (
          <span
            key={index}
            className={index === currentBannerIndex ? "active-dot" : "dot"}
            onClick={() => setCurrentBannerIndex(index)}
          >
            &#8226;
          </span>
        ))}
      </div>
      <div className="hero-home-main-div">
        <img src={blackBlur} className="black-blur" alt="blur" />
        <div className="main-content">
          <Zoom damping={0.1}>
            <p className="main-heading">
              EMPOWER YOUR BUSINESS WITH <span>CONVERSATIONAL AI</span>
            </p>
          </Zoom>

          <Zoom damping={0.4}>
            <p className="sub-text">{subText}</p>
          </Zoom>

          <div className="input-main-div">
            <Zoom damping={0.6}>
              <div className="input-main-container">
                <input
                  type="text"
                  placeholder="Example of what to type..."
                  className="search-input-box"
                  value={message}
                  onChange={handleChange}
                />

                <button className="generate-btn" onClick={handlePopup}>
                  Generate
                  <img src={starsIcon} className="stars-icon" alt="stars" />
                </button>
              </div>
            </Zoom>
          </div>

          <div
            className={
              reciveMsg && reciveMsg.length > 0
                ? "reponse-container"
                : "response-container-notrecive"
            }
          >
            {reciveMsg && <p className="reposne-message">{reciveMsg}</p>}
          </div>

          <Zoom damping={0.8}>
            <div className="suggested-prompts-div">
              <p className="prompts-title">Example Prompts</p>

              <Zoom damping={0.9}>
                <div className="prompts-container">
                  {prompts.map((item, index) => (
                    <div
                      key={index}
                      className="prompt-main"
                      onClick={() => handlePromptClick(item)}
                    >
                      <p className="prompt-text">{item}</p>
                    </div>
                  ))}
                </div>
              </Zoom>
            </div>
          </Zoom>

          <Zoom damping={0.1}>
            <div className="base-paragraph-div">
              <p className="para-heading">
                <span>Neurobridge Minsky</span> <br />
                Where your data becomes intelligence
              </p>
              <p className="main-para-text">{paraText}</p>
            </div>
          </Zoom>
        </div>
      </div>
    </>
  );
};

export default Hero;
