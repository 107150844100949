import React from 'react';
import '../../styles/AboutUs/AboutUs.css';
import bg1 from '../../assets/aboutUs/Rectangle 280.webp';
import bg2 from '../../assets/aboutUs/Rectangle 265.webp';
import bg5 from '../../assets/aboutUs/Rectangle 264.webp';
import bg4 from '../../assets/aboutUs/Rectangle 244 (1).webp';
import bg3 from '../../assets/aboutUs/Rectangle 271.webp';

export const AboutUsHeader = () => {
  return (
    <div className='aboutus-header'>
      <img src={bg1} className='bg1 fade-in' alt='img' />
      <img src={bg2} className='bg2 fade-in' alt='img' />
      <img src={bg3} className='bg3 fade-in' alt='img' />
      <img src={bg4} className='bg4 fade-in' alt='img' />
      <img src={bg5} className='bg5 fade-in' alt='img' />
      <svg
        xmlns='http://www.w3.org/2000/svg'
        width='347'
        height='227'
        viewBox='0 0 347 227'
        fill='none'
        className='header-svg'
      >
        <g filter='url(#filter0_f_347_297)'>
          <ellipse
            cx='173.5'
            cy='55.9784'
            rx='127.5'
            ry='124.978'
            fill='#E25858'
            fill-opacity='0.5'
          />
        </g>
        <defs>
          <filter
            id='filter0_f_347_297'
            x='0.599998'
            y='-114.4'
            width='345.8'
            height='340.757'
            filterUnits='userSpaceOnUse'
            color-interpolation-filters='sRGB'
          >
            <feFlood flood-opacity='0' result='BackgroundImageFix' />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='BackgroundImageFix'
              result='shape'
            />
            <feGaussianBlur
              stdDeviation='22.7'
              result='effect1_foregroundBlur_347_297'
            />
          </filter>
        </defs>
      </svg>
      <span className='pricing-top-heading'>About NeuroBridge 2024</span>
      <section className='contact-main-header' style={{ marginTop: '5rem' }}>
        {' '}
        <h2>About US</h2>
        <p>
          Neurobridge Tech is our dream turned reality—a place where AI <br />
          meets real-world data, making sense of it in real-time
        </p>
      </section>
        
    </div>
  );
};
