import React from "react";
import "../../styles/Home/Pricing.css";
import blog from "../../assets/picture/detailed-blog-1.webp";
import popular1 from "../../assets/picture/blog.webp";
import popular2 from "../../assets/picture/popular-blog-2.webp";
import popular3 from "../../assets/picture/popular-blog-3.webp";
import { FaInstagram } from "react-icons/fa";
import { FaLinkedin, FaXTwitter } from "react-icons/fa6";
import { Helmet } from "react-helmet";
const Streamline = () => {
  const shareUrl =
    "https://neurobridge.tech/blogs/streamline-customer-support-with-ai-powered-external-bots-with-minsky";
  const handleShare = (platform) => {
    switch (platform) {
      case "instagram":
        window.open(`https://www.instagram.com/?url=${shareUrl}`);
        break;
      case "linkedin":
        window.open(`https://www.linkedin.com/shareArticle?url=${shareUrl}`);
        break;
      case "twitter":
        window.open(`https://twitter.com/intent/tweet?url=${shareUrl}`);
        break;
      default:
        break;
    }
  };
  const TopicButtons = [
    { id: 0, name: "AI" },
    { id: 1, name: "Generative AI" },
    { id: 2, name: "Filmmaking" },
    { id: 3, name: "Star Wars" },
  ];

  const line = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="2"
      viewBox="0 0 100 2"
      fill="none"
    >
      <path
        d="M231 1.00002L1 1"
        stroke="#9B58E2"
        stroke-width="25"
        stroke-linecap="round"
      />
    </svg>
  );
  const cardsPopular = [
    {
      image: popular1,
      heading: "April 1, 2024",
      subheading: "An Effective Revolutionary Method for Conversational AI",
      linkTo: "/blogs/an-effective-revolutionary-method-for-conversational-ai",
    },
    {
      image: popular2,
      heading: "April 18, 2024",
      subheading:
        "Finding Your Perfect AI Audio Generator: A Step-by-Step Guide",
      linkTo:
        "/blogs/finding-your-perfect-ai-audio-generator-a-step-by-step-guide",
    },
    {
      image: popular3,
      heading: "April 15, 2024",
      subheading:
        "Enhancing Customer Experiences: The Role of AI-Based Chatbots",
      linkTo:
        "/blogs/enhancing-customer-experiences-the-role-of-ai-based-chatbots",
    },
  ];

  const Card = ({ image, heading, subheading, linkTo }) => (
    <div className="card">
      <div className="image-div">
        <a href={linkTo}>
          <img className="img" src={image} alt={heading} />
        </a>
      </div>
      <div className="card-content">
        <h1 className="tailored" style={{ color: "#9B58E2", fontSize: "15px" }}>
          {heading}
          <h1 className="subheading al-references-heading">
            {subheading}
            <br />

            <p1 style={{ cursor: "pointer" }}>
              <a
                style={{ color: "#9B58E2", textDecoration: "none" }}
                href={linkTo}
              >
                Read More...
              </a>
            </p1>
          </h1>
        </h1>
      </div>
    </div>
  );
  return (
    <div className="innerBlog">
      <Helmet>
        <title>
          Streamline Customer Support With AI-powered External Bots | Generative
          AI Chatbot
        </title>
        <meta
          name="description"
          content="Enhance Your Customer Support With AI-powered External Bots and Generative AI Chatbot Technology. Streamline Your Processes and Improve Efficiency With Our Cutting-edge Solutions. Visit Now!"
        />
      </Helmet>
      <div className="blog-div">
        <div className="blog-image">
          <img className="blog-img" src={blog} alt="" />
        </div>
        <div className="text-div-blog">
          <h2 className="lorem-text">Date</h2>
          <h2
            className="top-heading-blog"
            style={{ opacity: "0.6", color: "#FFF" }}
          >
            March 29, 2024
          </h2>
          {line}
          <h2 className="lorem-text">Keywords</h2>
          <h2 className="topic">
            {TopicButtons.map((d, index) => (
              <button key={index}>{d.name}</button>
            ))}
          </h2>
          {line}
          <h2 className="lorem-text">Share</h2>
          <div className="shareLogo">
            <FaInstagram
              style={{ color: "#fff", cursor: "pointer" }}
              onClick={() => handleShare("instagram")}
            />
            <FaXTwitter
              style={{ color: "#FFF", cursor: "pointer" }}
              onClick={() => handleShare("twitter")}
            />
            <FaLinkedin
              style={{ color: "#FFF", cursor: "pointer" }}
              onClick={() => handleShare("linkedin")}
            />
          </div>
        </div>
      </div>
      <br />
      <br />
      <div className="blog-div-content">
        <h2 className="ai-references">
          Streamline Customer Support with AI-Powered External Bots with Minsky
        </h2>
        <h1 className="text-lorem">
          Do you ever find yourself struggling to keep up with customer
          inquiries and provide top-notch service, especially outside of regular
          business hours? It's a common challenge for many businesses, but
          there's a solution on the horizon.
          <br />
          <br />
          Enter external bots– these clever AI tools are changing the game in
          customer service. It’s like having someone available to assist your
          customers 24/7, no matter the time or day. These bots are powered by
          cutting-edge technology like artificial intelligence (AI) and RAG
          LLMs, allowing them to understand and respond to customer queries in
          real-time.
          <br />
          <br />
          With external bots, you can provide instant, personalised support to
          your customers, boosting their satisfaction levels and loyalty to your
          brand. These bots are not just helpful; they're also incredibly
          efficient. By automating routine tasks and inquiries, they free up
          your team to focus on more complex issues, ultimately improving the
          overall customer experience.
          <br />
          <br />
          Get Ready to take your customer service to the next level. It's time
          to utilise external bots. By integrating them into your customer
          service strategy, you can ensure that your customers receive the
          assistance they need, whenever they need it. Eliminate long wait times
          and experience more happier customers. Try Minsky AI and personalise
          external bots according to your needs and watch your customer
          satisfaction and net promotional rate soar.
        </h1>
        <h1 className="blog-card-heading">Similar Blogs</h1>
        <div className="blog-card-div">
          {cardsPopular.map((card, index) => (
            <Card key={index} {...card} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Streamline;
