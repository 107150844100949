import React, { useState } from "react";
import { FaArrowRight } from "react-icons/fa";
const positions = [
  {
    title: "Front-End Developer (React Native)",
    location: "Remote",
    type: "Full Time",
    description: (
      <ul>
        <li>
          Creating and implementing user interfaces for mobile applications that
          work seamlessly on both iOS and Android platforms using React Native.
        </li>
        <li>
          Enhancing the performance of mobile applications by optimising the
          code, managing the state efficiently, and reducing load times.
        </li>
        <li>
          Collaborating with native developers to integrate native modules and
          APIs when necessary, ensuring seamless functionality and performance.
        </li>
      </ul>
    ),
    link: "https://forms.gle/pUnNQQHPom6M1rnQ9",
    category: "Development",
  },
  {
    title: "LLM Research Intern",
    location: "Remote",
    type: "Internship",
    description: (
      <ul>
        <li>
          Assist in fine-tuning and training large language models using
          state-of-the-art techniques.
        </li>
        <li>
          Develop and implement new features to improve model performance,
          especially for multilingual support and Hinglish.
        </li>
        <li>
          Conduct research on the latest advancements in natural language
          processing and apply findings to improve our models.
        </li>
        <li>
          Collaborate with cross-functional teams to integrate LLM capabilities
          into our products.
        </li>
        <li>Document research findings, experiments, and model updates.</li>
      </ul>
    ),
    link: "https://forms.gle/6SeAr6N5sVZabQ4V9",
    category: "Research",
  },
  {
    title: "Text-to-Speech (TTS) Intern",
    location: "Remote",
    type: "Internship",
    description: (
      <ul>
        <li>
          Develop and optimize TTS models to generate high-quality,
          natural-sounding speech.
        </li>
        <li>
          Conduct experiments to improve prosody, intonation, and pronunciation
          in TTS systems.
        </li>
        <li>
          Work on multi-language and code-mixed language support for TTS models.
        </li>
        <li>
          Integrate TTS models with our existing products and ensure smooth
          functionality.
        </li>
        <li>Document research, experiments, and model updates.</li>
      </ul>
    ),
    link: "https://forms.gle/e16rB5FeLXesFU9e9",
    category: "Engineering",
  },
  {
    title: "Speech-to-Text (STT) Intern",
    location: "Remote",
    type: "Internship",
    description: (
      <ul>
        <li>
          Develop and enhance STT models to achieve high accuracy in speech
          recognition.
        </li>
        <li>
          Conduct experiments to improve model performance, especially in noisy
          and varied acoustic environments.
        </li>
        <li>
          Work on multi-language support and handling code-mixed languages in
          STT systems.
        </li>
        <li>
          Integrate STT models with our existing products and ensure seamless
          functionality.
        </li>
        <li>Document research, experiments, and model updates.</li>
      </ul>
    ),
    link: "https://forms.gle/ZTnXKHMbziJeZfFQ7",
    category: "Engineering",
  },
  {
    title: "Image Models Research Intern",
    location: "Remote",
    type: "Internship",
    description: (
      <ul>
        <li>Develop and optimize image recognition and generation models.</li>{" "}
        <li>
          Conduct experiments to improve model performance on tasks such as
          classification, detection, segmentation, and image synthesis.
        </li>{" "}
        <li>
          Work on multi-modal integration of image models with text and speech.
        </li>{" "}
        <li>
          Collaborate with cross-functional teams to integrate image model
          capabilities into our products.
        </li>{" "}
        <li>Document research findings, experiments, and model updates.</li>
      </ul>
    ),
    link: "https://forms.gle/THXqd6PGTKfAwukk7",
    category: "Research",
  },
  {
    title: "DevOps & Deployment Intern",
    location: "Remote",
    type: "Internship",
    description: (
      <ul>
        <li>
          Assist in the development and maintenance of CI/CD pipelines for
          various projects.
        </li>{" "}
        <li>
          Work on automating deployment processes for applications in different
          environments.
        </li>{" "}
        <li>
          Collaborate with development teams to ensure seamless integration and
          deployment of new features.
        </li>{" "}
        <li>
          Monitor system performance, troubleshoot issues, and optimize
          deployment workflows.
        </li>{" "}
        <li>
          Document deployment processes, configurations, and best practices.
        </li>
      </ul>
    ),
    link: "https://forms.gle/opitWp8uK2YdKA8S8",
    category: "DevOps",
  },
  {
    title: "React JS Development Intern",
    location: "Remote",
    type: "Internship",
    description: (
      <ul>
        <li>
          Assist in the development and maintenance of web applications using
          React.js.
        </li>
        <li>
          Collaborate with UI/UX designers to implement user-friendly
          interfaces.
        </li>
        <li>
          Work on optimizing applications for performance and scalability.
        </li>
        <li>Debug and troubleshoot issues in existing applications.</li>
        <li>Document code, development processes, and best practices.</li>
      </ul>
    ),
    link: "https://forms.gle/jRjxQGxzMm4FKM5w5",
    category: "Development",
  },
  {
    title: "React Native Development Intern",
    location: "Remote",
    type: "Internship",
    description: (
      <ul>
        <li>
          Assist in the development and maintenance of mobile applications using
          React Native.
        </li>{" "}
        <li>
          Collaborate with designers to implement visually appealing and
          user-friendly interfaces.
        </li>{" "}
        <li>
          Optimize applications for maximum performance across different devices
          and platforms.
        </li>{" "}
        <li>Debug and troubleshoot issues in existing applications.</li>{" "}
        <li>Document code, development processes, and best practices.</li>
      </ul>
    ),
    link: "https://forms.gle/v3HCFeDQPGkE2qTz7",
    category: "Development",
  },
  // Add more positions if needed for testing
];

const calculateCategoryCounts = (positions) => {
  const categoryCounts = { "All Positions": positions.length };
  positions.forEach((position) => {
    if (categoryCounts[position.category]) {
      categoryCounts[position.category]++;
    } else {
      categoryCounts[position.category] = 1;
    }
  });
  return categoryCounts;
};

const JobBoard = () => {
  const [selectedCategory, setSelectedCategory] = useState("All Positions");
  const [visibleCount, setVisibleCount] = useState(5);

  const filteredPositions =
    selectedCategory === "All Positions"
      ? positions
      : positions.filter((position) => position.category === selectedCategory);

  const categoryCounts = calculateCategoryCounts(positions);
  const categories = Object.keys(categoryCounts).map((name) => ({
    name,
    count: categoryCounts[name],
  }));

  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 5);
  };
  const openLinkInNewWindow = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div className="container">
      <h1>We have {categoryCounts[selectedCategory]} open positions now!</h1>
      <div className="row">
        <div className="categories">
          {categories.map((category) => (
            <h6 key={category.name}>
              <button
                className={selectedCategory === category.name ? "active" : ""}
                onClick={() => setSelectedCategory(category.name)}
              >
                {category.name} ({category.count})
              </button>
            </h6>
          ))}
          <div className="text">
            We are always seeking talented people. In case you cannot find your
            desired position here, please send us your LinkedIn profile and give
            us your contact information. We will be in touch.
          </div>
          <div
            onClick={() =>
              openLinkInNewWindow("https://forms.gle/ps4UizpgcxMUoEyT7")
            }
            className="share-button"
          >
            Share your LinkedIn profile
          </div>
        </div>
        <div className="col-md-8">
          <div className="job-listings">
            {filteredPositions.slice(0, visibleCount).map((position) => (
              <div className="job-listing" key={position.title}>
                <h2>{position.title}</h2>
                <p>
                  <span className="location">{position.location}</span>
                  <span className="type">{position.type}</span>
                </p>
                <div className="description">{position.description}</div>
                <button
                  onClick={() => openLinkInNewWindow(position.link)}
                  className="apply-now"
                >
                  <p2 target="_blank" rel="noopener noreferrer">
                    Apply Now
                  </p2>
                  <FaArrowRight />
                </button>
              </div>
            ))}
          </div>
          {filteredPositions.length > visibleCount && (
            <button onClick={handleShowMore} className="show-more">
              Show More...
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default JobBoard;
