import React, { useState } from 'react';
import '../../styles/Contact/Contact.css';
import emailjs from 'emailjs-com';

export const ContactForm = () => {
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Here you can perform form validation and submit the form data
    console.log({ fullName, email, message });
    emailjs
      .send(
        'service_1nrpixs',
        'template_56ov65j',
        {
          to_email: 'contact@neurobridge.tech',
          subject: `${fullName} Want to Book Your Demo`,
          message: `
      User Name : ${fullName}
      User Email : ${email} 
      User Mobile Number: ${mobileNumber}  
      Message: ${message}`,
        },
        'AQBTJSc7MYmza3103'
      )
      .then(
        (response) => {
          console.log('Email sent successfully:', response);
          alert('Email sent successfully!');
        },
        (error) => {
          console.error('Error sending email:', error);
          alert('Failed to send email. Please try again later.');
        }
      );

    // Reset form fields
    setFullName('');
    setEmail('');
    setMessage('');
    setMobileNumber('');
  };

  return (
    <div className='form-main'>
      <h2>Get in touch with us.</h2>
      <p>
        {' '}
        You can reach us anytime via<span> help@neurobridge.tech</span>
      </p>
      <form onSubmit={handleSubmit} className='form'>
        <div className='form-input'>
          <label htmlFor='fullName'>Full Name:</label>
          <input
            type='text'
            id='fullName'
            value={fullName}
            placeholder='Your Name...'
            onChange={(e) => setFullName(e.target.value)}
            required
          />
        </div>
        <div className='form-input'>
          <label htmlFor='email'>Email:</label>
          <input
            type='email'
            id='email'
            value={email}
            placeholder='Your Email...'
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className='form-input'>
          <label htmlFor='email'>Mobile Number:</label>
          <input
            type='number'
            id='mobile'
            value={mobileNumber}
            placeholder='Your Email...'
            onChange={(e) => setMobileNumber(e.target.value)}
            required
          />
        </div>
        <div className='form-input'>
          <label htmlFor='message'>How can we help?</label>
          <textarea
            id='message'
            value={message}
            placeholder='Tell us what you’re thinking...'
            onChange={(e) => setMessage(e.target.value)}
            required
          ></textarea>
        </div>
        <div className='form-button'>
          <button type='submit'>Submit</button>
        </div>
      </form>
    </div>
  );
};
