import React, { useState } from "react";
import "../../styles/Home/Pricing.css";
import { Helmet } from "react-helmet";
import TheLatest from "../../assets/picture/the-latest-in-smart-conversation-technology.webp";
import AdvancedArtificialIntelligenceGeneratorForInnovativeSolutions from "../../assets/picture/advanced-artificial-intelligence-generator-for-innovative-solutions.webp";
import HowAIChatbotsTransformBusinessCommunication from "../../assets/picture/how-aI-chatbots-transform-business-communication.webp";
import YourFriendly from "../../assets/picture/your-friendly-generative-ai-chatbot.webp";
import ChanakyaInsightsOn from "../../assets/picture/Chanakya-Insights-on-Artificial-Neural-Networks.webp";
import AITextGenerator from "../../assets/picture/best-artificial-intelligence-ai-text-generator-chanakya.webp";
import reinforcement from "../../assets/picture/best-ai-reinforcement-learning-in-machine-learning-chanakya.webp";
import ChatGPTAlternative from "../../assets/picture/best-chatgpt-alternative-website-chanakya.webp";
import ChanakyaStandOut from "../../assets/picture/ChanakyaStandOut.webp";
import ConversationalAI from "../../assets/picture/best-conversational-ai-website.webp";
import ExploringAI from "../../assets/picture/Best AI Website for all Overall Assistance.webp";
import popular1 from "../../assets/picture/Convenient Solutions.webp";
import popular2 from "../../assets/picture/popular-blog-2.webp";
import popular3 from "../../assets/picture/FromWordsToSound.webp";
import discovering from "../../assets/picture/popular-blog-1.webp";
import effective from "../../assets/picture/blog.webp";
import enhancing from "../../assets/picture/popular-blog-3.webp";
import Detailed1 from "../../assets/picture/Revolutionising Communication.webp";
import Detailed2 from "../../assets/picture/Streamlining Operations.webp";
import Detailed3 from "../../assets/picture/The Ultimate AI.webp";
import exploring from "../../assets/picture/detailed-blog-3.webp";
import smoother from "../../assets/picture/detailed-blog-2.webp";
import streamline from "../../assets/picture/detailed-blog-1.webp";
import img2 from "../../assets/pricing/Group 96.webp";
import img3 from "../../assets/pricing/Group 98.webp";
import img4 from "../../assets/pricing/Group 99.webp";
import img5 from "../../assets/pricing/Group.webp";
import blog from "../../assets/picture/Captivating Audiences.webp";
import { useMobile } from "../../globalComponents/isMobileContext";

const Blogs = () => {
  const { isMobile } = useMobile();
  const [visibleCount, setVisibleCount] = useState(3);
  const [visibleCountDetailed, setVisibleCountDetailed] = useState(3);
  /*  const pricingButton = [
    { id: 0, name: "All" },
    { id: 1, name: "ASR" },
    { id: 2, name: "LLMs" },
    { id: 3, name: "VectorDB Storage" },
  ];
*/

  const cardsPopular = [
    {
      image: TheLatest,
      heading: "September 18, 2024",
      subheading:
        "Generative AI Chatbots: The Latest in Smart Conversation Technology",
      linkTo: "/blogs/chanakya-on-generative-ai-chatbots-insights-strategies",
    },
    {
      image: HowAIChatbotsTransformBusinessCommunication,
      heading: "September 13, 2024",
      subheading:
        "Transforming Customer Interaction: The Impact of AI Chatbots",
      linkTo: "/blogs/how-ai-chatbots-transform-business-communication",
    },
    {
      image: AdvancedArtificialIntelligenceGeneratorForInnovativeSolutions,
      heading: "September 13, 2024",
      subheading: "How Chanakya Works as Artificial Intelligence Generator",
      linkTo:
        "/blogs/advanced-artificial-intelligence-generator-for-innovative-solutions",
    },
    {
      image: YourFriendly,
      heading: "September 10, 2024",
      subheading: "Meet Chanakya: Your Friendly Generative AI Chatbot",
      linkTo: "/blogs/your-friendly-generative-ai-chatbot",
    },
    {
      image: ChanakyaInsightsOn,
      heading: "September 7, 2024",
      subheading:
        "Artificial Neural Networks Explained: Building Smarter AI Systems with Chanakya",
      linkTo:
        "/blogs/chanakya-insights-on-artificial-neural-networks-expert-analysis-trends",
    },
    {
      image: AITextGenerator,
      heading: "August 28, 2024",
      subheading:
        "Innovations in AI Text Generation: How Chanakya Shapes the Future of Writing",
      linkTo: "/blogs/best-artificial-intelligence-ai-text-generator-chanakya",
    },
    {
      image: reinforcement,
      heading: "August 23, 2024",
      subheading:
        "What is Reinforcement Learning in Machine Learning? Easy Guide",
      linkTo:
        "/blogs/best-ai-reinforcement-learning-in-machine-learning-chanakya",
    },
    {
      image: ChatGPTAlternative,
      heading: "August 23, 2024",
      subheading: "Looking for a ChatGPT Alternative? Check Out Chanakya!",
      linkTo: "/blogs/best-chatgpt-alternative-website-chanakya",
    },
    {
      image: ChanakyaStandOut,
      heading: "July 29, 2024",
      subheading: "What Makes Chanakya Stand Out As An Best AI Chatbot",
      linkTo: "/blogs/best-ai-chatbot-chanakya",
    },
    {
      image: ConversationalAI,
      heading: "August 5, 2024, 2024",
      subheading:
        "What is Conversational AI? How Does Chanakya AI Make Conversations better?",
      linkTo: "/blogs/best-conversational-ai-website",
    },
    {
      image: ExploringAI,
      heading: "August 22, 2024",
      subheading: "Exploring AI Website: What Chanakya AI has to Offer",
      linkTo: "/blogs/best-ai-website-for-all-overall-assistance",
    },
  ];
  const cards = [
    {
      image: popular1,
      heading: "May 28, 2024",
      subheading:
        "Convenient Solutions: Discovering the Best AI Voice Generator Platforms Online",
      linkTo:
        "/blogs/convenient-solutions-discovering-the-best-ai-voice-generator-platforms-online",
    },
    {
      image: popular2,
      heading: "April 18, 2024",
      subheading:
        "Finding Your Perfect AI Audio Generator: A Step-by-Step Guide",
      linkTo:
        "/blogs/finding-your-perfect-ai-audio-generator-a-step-by-step-guide",
    },
    {
      image: popular3,
      heading: "May 29, 2024",
      subheading:
        "From Words to Sound: Choosing the Perfect Text-to-Speech Converter for Your Projects",
      linkTo:
        "/blogs/from-words-to-sound-choosing-the-perfect-text-to-speech-converter-for-your-projects",
    },
    {
      image: discovering,
      heading: "April 1, 2024",
      subheading:
        "Discovering The Versatility Of Minsky Ai In Real-World Applications",
      linkTo:
        "/blogs/discovering-the-versatility-of-minsky-ai-in-real-world-applications",
    },
    {
      image: effective,
      heading: "April 1, 2024",
      subheading: "An Effective Revolutionary Method for Conversational AI",
      linkTo: "/blogs/an-effective-revolutionary-method-for-conversational-ai",
    },
    {
      image: enhancing,
      heading: "April 15, 2024",
      subheading:
        "Enhancing Customer Experiences: The Role of AI-Based Chatbots",
      linkTo:
        "/blogs/enhancing-customer-experiences-the-role-of-ai-based-chatbots",
    },
    {
      image: Detailed1,
      heading: "May 30, 2024",
      subheading:
        "Revolutionising Communication: How AI Chatbots Are Reshaping Interaction",
      linkTo:
        "/blogs/revolutionising-communication-how-ai-chatbots-are-reshaping-interaction",
    },
    {
      image: Detailed2,
      heading: "June 1, 2024",
      subheading:
        "Streamlining Operations: Incorporating AI Chatbots into Your Workflow",
      linkTo:
        "/blogs/streamlining-operations-incorporating-ai-chatbots-into-your-workflow",
    },
    {
      image: Detailed3,
      heading: "May 30, 2024",
      subheading: "Minsky: The Ultimate AI Audio Generator",
      linkTo: "/blogs/minsky-the-ultimate-ai-audio-generator",
    },
    {
      image: exploring,
      heading: "January 25, 2024",
      subheading:
        "Exploring the Future with Neurobridge: A Next-Generation AI Model",
      linkTo:
        "/blogs/exploring-the-future-with-neurobridge-a-next-generation-ai-model",
    },
    {
      image: smoother,
      heading: "March 30, 2024",
      subheading:
        "Smoother Operations with Advanced Conversational AI: Simple Integration Strategies",
      linkTo:
        "/blogs/smoother-operations-with-advanced-conversational-ai-simple-integration-strategies",
    },
    {
      image: streamline,
      heading: "March 29, 2024",
      subheading:
        "Streamline Customer Support with AI-Powered External Bots with Minsky",
      linkTo:
        "/blogs/streamline-customer-support-with-ai-powered-external-bots-with-minsky",
    },
  ];

  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 3);
  };

  const handleShowMoreDetailed = () => {
    setVisibleCountDetailed((prevCount) => prevCount + 3);
  };
  const Card = ({ image, heading, subheading, linkTo }) => (
    <div className="card">
      <div className="image-div">
        <a href={linkTo}>
          <img className="img" src={image} alt={heading} />
        </a>
      </div>
      <div className="card-content">
        <h1 className="tailored" style={{ color: "#9B58E2", fontSize: "15px" }}>
          {heading}
          <h1 className="subheading">
            {subheading}
            <br />

            <p1 style={{ cursor: "pointer" }}>
              <a
                style={{ color: "#9B58E2", textDecoration: "none" }}
                href={linkTo}
              >
                Read More...
              </a>
            </p1>
          </h1>
        </h1>
      </div>
    </div>
  );
  return (
    <div className="innerBlog">
      <Helmet>
        <title>
          Exploring the World of AI Through Informative Blogs: Neurobridge
          Minsky
        </title>
        <meta
          name="description"
          content="Discover a World of Informative Blogs on AI and Chat With Our Generative Chatbot, Neurobridge Minsky. Stay Informed and Up-to-date on the Latest Advancements in Artificial Intelligence. Visit Now!"
        />
      </Helmet>
      {!isMobile && (
        <span className="pricing-top-heading">NeuroBridge Blogs 2024</span>
      )}
      <div className="pricing-top-section">
        <img src={img3} alt="img1" className="pricing-img1 fade-in" />
        <img src={img2} alt="img2" className="pricing-img3 fade-in" />
        <img src={img3} alt="img3" className="pricing-img2 fade-in" />
        <img src={img4} alt="img4" className="pricing-img5 fade-in" />
        <img src={img5} alt="img5" className="pricing-img4 fade-in" />
        <p className="sub-heading">
          Blogs for All of your
          <br /> Comprehensive
          <span>
            {" "}
            Artificial <br />
            Intelligence
          </span>{" "}
          Topics
        </p>
      </div>

      {/*<div className='pricing-btn'>
        {pricingButton.map((d, index) => (
          <button style={{ borderRadius: '109px' }} key={index}>
            {d.name}
          </button>
        ))}
      </div> */}
      <div>
        <div className="blog-div">
          {isMobile && (
            <div className="text-div-blog" style={{ marginBottom: "1rem" }}>
              <h1 className="trending-heading">Trending Now</h1>
              <a
                style={{ textDecoration: "none" }}
                href="blogs/the-real-game-changer"
              >
                <h2 className="top-heading-blog">RAG vs Semantic Search:</h2>
                <h2 className="lorem-text">The Real Game Changer</h2>
              </a>
              <a
                style={{ textDecoration: "none" }}
                href="blogs/enhancing-decision-making"
              >
                <h2 className="top-heading-blog">RAG For Businesses:</h2>
                <h2 className="lorem-text">Enhancing Decision Making</h2>
              </a>
              <a
                style={{ textDecoration: "none" }}
                href="/blogs/the-impact-of-internal-bots-on-business"
              >
                <h2 className="top-heading-blog">Boosting Productivity:</h2>
                <h2 className="lorem-text">
                  The Impact Of Internal Bots On Business
                </h2>
              </a>
            </div>
          )}
          <div className="blog-image">
            <a href="/blogs/captivating-audiences-the-power-of-ai-voiceover-in-storytelling">
              <img className="blog-img" src={blog} alt="" />
            </a>
          </div>
          {!isMobile && (
            <div className="text-div-blog">
              <h1 className="trending-heading">Trending Now</h1>
              <a
                style={{ textDecoration: "none" }}
                href="blogs/the-real-game-changer"
              >
                <h2 className="top-heading-blog">RAG vs Semantic Search:</h2>
                <h2 className="lorem-text">The Real Game Changer</h2>
              </a>
              <a
                style={{ textDecoration: "none" }}
                href="blogs/enhancing-decision-making"
              >
                <h2 className="top-heading-blog">RAG For Businesses:</h2>
                <h2 className="lorem-text">Enhancing Decision Making</h2>
              </a>
              <a
                style={{ textDecoration: "none" }}
                href="/blogs/the-impact-of-internal-bots-on-business"
              >
                <h2 className="top-heading-blog">Boosting Productivity:</h2>
                <h2 className="lorem-text">
                  The Impact Of Internal Bots On Business
                </h2>
              </a>
            </div>
          )}
        </div>
        <div className="blog-div-content">
          <h1 className="tailored">May 31, 2024</h1>
          <h2 className="ai-references">
            Captivating Audiences: The Power of AI Voiceover in Storytelling
          </h2>
          <h1 className="text-lorem">
            In today's digital age, it is very important to gain more and more
            audiences to grow your online presence. And, the audience is always
            attracted to something more interesting and knowledgeable.{" "}
            <p1 style={{ cursor: "pointer" }}>
              <a
                href="/blogs/captivating-audiences-the-power-of-ai-voiceover-in-storytelling"
                style={{ textDecoration: "none", color: "#E25858" }}
              >
                Read More...
              </a>
            </p1>
          </h1>
          <h1 className="blog-card-heading">Popular</h1>
          <div className="blog-card-div">
            {cardsPopular.slice(0, visibleCount).map((card, index) => (
              <Card key={index} {...card} />
            ))}
          </div>
          {cardsPopular.length > visibleCount && (
            <button onClick={handleShowMore} className="show-more-blogs">
              Show More...
            </button>
          )}
          <h1 className="blog-card-heading">Detailed</h1>
          <div className="blog-card-div">
            {cards.slice(0, visibleCountDetailed).map((card, index) => (
              <Card key={index} {...card} />
            ))}
          </div>
          {cards.length > visibleCountDetailed && (
            <button
              onClick={handleShowMoreDetailed}
              className="show-more-blogs"
            >
              Show More...
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default Blogs;
