import React from "react";
import { Helmet } from "react-helmet";
import Hero from "../components/Home/Hero";
import OurNumbers from "../components/Home/OurNumbers";
import Circuit from "../components/Home/Circuit";
import MinskyContainer from "../components/Home/MinskyContainer";
import OurBenefits from "../components/Home/OurBenefits";
import DiscoverCards from "../components/Home/DiscoverCards";
import ScheduleDemo from "../components/Home/ScheduleDemo";
// import AdditionalSection from '../components/Home/AdditionalSection';
// import UniqueSection from '../components/Home/UniqueSection';
import "../styles/Home/HomePage.css";
import Testimonials from "../components/Home/Testimonials";
import banner from "../assets/background/topBackground.webp";
const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>
          Top Trusted Generative AI Chatbot for All Solutions at Neurobridge
          Minsky
        </title>
        <meta
          name="description"
          content="Unlock the Power of AI With Neurobridge Minsky, the Top Trusted Platform for Rag, AI Assistants, Generative Powered Search, Question-answering, AI Summarization and AI Agents for Unmatched Performance and Results. Visit Now!"
        />
      </Helmet>

      <div
        className="home-page-main-div"
        style={{
          backgroundImage: `url(${banner})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100%",
        }}
      >
        <div className="components-container">
          <Hero />
          <OurNumbers />
          <Circuit />
          <MinskyContainer />
          <OurBenefits />
          <DiscoverCards />
          <ScheduleDemo />
          <Testimonials />
          {/* <AdditionalSection /> */}
          {/* <UniqueSection /> */}
        </div>
      </div>
    </>
  );
};

export default HomePage;
