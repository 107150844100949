import React, { useState } from 'react';
import '../../../styles/Home/Pricing.css';

const MonthlyPricing = () => {
  const [isSelected, setIsSelected] = useState(0);

  const packDetails = [
    [
      { id: 1, name: 'Indexing up to 100 MB of website data' },
      { id: 2, name: 'AI generative search' },
      { id: 3, name: 'Public-facing chatbot' },
      { id: 4, name: 'RAG' },
      { id: 5, name: 'Unlimited Tokens' },
      { id: 6, name: 'Live Status Updates' },
      { id: 7, name: 'Statistics' },
    ],
    [
      { id: 1, name: 'Basic features' },
      { id: 2, name: 'Storage up to 5 GB' },
      { id: 3, name: '1 AI Assistant' },
      { id: 4, name: 'Document Q&A for 3 users' },
      { id: 5, name: 'Unlimited Tokens' },
      { id: 6, name: 'Live Status Updates' },
      { id: 7, name: 'Statistics' },
    ],
    [
      { id: 1, name: 'Plus features' },
      { id: 2, name: 'Storage up to 20 GB' },
      { id: 3, name: '3 AI Assistants' },
      { id: 4, name: 'Document Summarization' },
      { id: 5, name: 'Data Generation & Augmentation for 10 users' },
      { id: 6, name: 'Live Status Updates' },
      { id: 7, name: 'Statistics' },
    ],
    [
      { id: 1, name: 'Premium features' },
      { id: 2, name: 'Unlimited storage' },
      { id: 3, name: 'Custom deployments' },
      { id: 4, name: 'More custom AI tasks' },
      { id: 5, name: 'Unlimited AI Assistants' },
      { id: 6, name: 'Live Status Updates' },
      { id: 7, name: 'Statistics' },
    ],
    [
      { id: 1, name: 'AI Tokens' },
      { id: 2, name: 'AI System Access' },
      { id: 3, name: 'All Features' },
      { id: 4, name: 'Staff Support' },
      { id: 5, name: 'Unlimited Tokens' },
      { id: 6, name: 'Live Status Updates' },
      { id: 7, name: 'Statistics' },
    ],
  ];
  const monthly = [
    { id: 1, name: 'Basic', price: 'Contact Us', discount: '20% off' },
    { id: 2, name: 'Plus', price: 'Contact Us', discount: '20% off' },
    { id: 3, name: 'Premium', price: 'Contact Us', discount: '20% off' },
    { id: 4, name: 'Enterprise', price: 'Contact Us', discount: '20% off' },
  ];

  //   const isSelectedHandler = () => {
  //     setIsSelected(false);
  //   };
  return (
    <div className='pricing-bottom-section'>
      <section className='pricing-pack-deatils'>
        <h2>{monthly[isSelected].name}</h2>
        <div className='pack-details'>
          {packDetails[isSelected].map((d, index) => (
            <span>
              <p key={index}>{d.name}</p>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
              >
                <circle cx='12' cy='12' r='12' fill='white' />
                <path
                  d='M6.94727 12.6315L10.1052 15.7894L17.0525 8.21045'
                  stroke='#E25858'
                  stroke-width='1.89474'
                  stroke-linecap='round'
                />
              </svg>
            </span>
          ))}
        </div>
      </section>
      <section className='pricing-section'>
        {monthly.map((d, index) => (
          <div
            key={index}
            onClick={() => setIsSelected(index)}
            className={isSelected === index ? 'selected-box' : 'pricing-box'}
          >
            <span>
              {isSelected === index ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='18'
                  height='15'
                  viewBox='0 0 18 15'
                  fill='none'
                  className='pricing-checked-svg'
                >
                  <path
                    d='M1.8418 7.89475L6.31548 12.3684L16.1576 1.63159'
                    stroke='#E25858'
                    stroke-width='2.68421'
                    stroke-linecap='round'
                  />
                </svg>
              ) : (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='34'
                  height='34'
                  viewBox='0 0 34 34'
                  fill='none'
                >
                  <circle
                    cx='17'
                    cy='17'
                    r='15.5'
                    stroke='white'
                    stroke-opacity='0.38'
                    stroke-width='3'
                  />
                </svg>
              )}
            </span>
            <span>
              {d.name}
              <p>{d.discount}</p>
            </span>
            <h3>
              {d.price === 'Contact Us' ? (
                <a
                  style={{ textDecoration: 'none', color: '#fff' }}
                  href='/contact'
                >
                  {d.price}
                </a>
              ) : (
                <span>
                  {typeof d.price === 'number' ? `₹${d.price}` : d.price}
                  <span>
                    {typeof d.price === 'number' ? '/Annually' : null}
                  </span>
                </span>
              )}
                         
            </h3>
          </div>
        ))}
      </section>
    </div>
  );
};

export default MonthlyPricing;
