import React from "react";
import "../../styles/Contact/Contact.css";
import { ContactForm } from "./ContactForm";
import { FaInstagram, FaLinkedin } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { SiGmail } from "react-icons/si";
import grp1 from "../../assets/contactus/Group__1___1_-removebg-preview.webp";
import grp2 from "../../assets/contactus/Group__2___1_-removebg-preview.webp";
import grp3 from "../../assets/contactus/Group__3___1_-removebg-preview.webp";
import grp4 from "../../assets/contactus/Group__4_-removebg-preview (2).webp";
import grp5 from "../../assets/contactus/Group 111 (2).webp";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

export const ContactPage = () => {
  return (
    <div className="contact-main">
      <Helmet>
        <title>
          Generative AI Chatbot for Seamless Contact Integration: Neurobridge
          Minsky
        </title>
        <meta
          name="description"
          content="Neurobridge Minsky Is Revolutionizing AI Technology With Our Generative AI Chatbot, Changing the Way You Interact With Artificial Intelligence. Learn More About How We Are Shaping the Future. Visit Now!"
        />
      </Helmet>
      <div className="contact-main-header">
        <img src={grp1} alt="grp1" className="grp1 fade-in" />
        <img src={grp2} alt="grp2" className="grp2 fade-in" />
        <img src={grp3} alt="grp3" className="grp3 fade-in" />
        <img src={grp4} alt="grp4" className="grp4 fade-in" />
        <img src={grp5} alt="grp5" className="grp5 fade-in" />
        <h2>Contact Us</h2>
        <p>
          For inquiries, assistance, or partnership opportunities,
          <br /> reach out to Neurobridge Tech via:
        </p>
        {/* <div className='contact-TTS' style={{ margin: '2rem' }}>
          <FaCalendarDays />
          View TTS Plan
        </div> */}
      </div>
      <div className="contact-main-body">
        <section className="contact-info">
          {/* <span>
            <h2>Get in touch</h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.
            </p>
          </span> */}

          {/* <span>
            <h2>
              <svg
                width="32"
                height="30"
                viewBox="0 0 32 30"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Message">
                  <path
                    id="Message_2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M23.8209 0.75C25.9441 0.75 27.9866 1.58917 29.4892 3.09492C30.9934 4.5975 31.8341 6.62417 31.8341 8.74583V21.2542C31.8341 25.6717 28.24 29.25 23.8209 29.25H8.17915C3.76006 29.25 0.16748 25.6717 0.16748 21.2542V8.74583C0.16748 4.32833 3.74423 0.75 8.17915 0.75H23.8209ZM26.34 11.105L26.4666 10.9783C26.8451 10.5192 26.8451 9.85417 26.4492 9.395C26.2291 9.15908 25.9267 9.015 25.6116 8.98333C25.2791 8.96592 24.9625 9.07833 24.7234 9.3L17.5841 15C16.6658 15.7616 15.3501 15.7616 14.4175 15L7.29248 9.3C6.80006 8.93583 6.11923 8.98333 5.70915 9.41083C5.28165 9.83833 5.23415 10.5192 5.59673 10.9942L5.80415 11.2L13.0083 16.8208C13.895 17.5175 14.9701 17.8975 16.0958 17.8975C17.2184 17.8975 18.3125 17.5175 19.1976 16.8208L26.34 11.105Z"
                    fill="white"
                  />
                </g>
              </svg>
              Chat with us
            </h2>
            <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.
            </p>
            <p style={{color:"white",fontWeight:"600"}}>help@neurobridge.tech</p>
          </span> */}

          <span>
            <h2>
              <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Call">
                  <path
                    id="Stroke 1"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M12.3758 13.6298C17.6946 18.9471 18.9012 12.7955 22.2877 16.1797C25.5525 19.4436 27.429 20.0975 23.2925 24.2329C22.7743 24.6493 19.4823 29.6589 7.91297 18.0928C-3.65781 6.52525 1.34894 3.22984 1.76546 2.71185C5.91198 -1.43495 6.55465 0.452429 9.81948 3.71636C13.206 7.10191 7.05704 8.31247 12.3758 13.6298Z"
                    fill="white"
                  />
                </g>
              </svg>
              Phone
            </h2>
            {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.
            </p> */}
            <p style={{ color: "white", fontWeight: "600" }}>
              help@neurobridge.tech
            </p>
          </span>

          <span>
            <h2>
              <svg
                width="24"
                height="28"
                viewBox="0 0 24 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="Location">
                  <path
                    id="Location_2"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M0.666504 11.7571C0.666504 5.62394 5.79168 0.666748 11.9911 0.666748C18.208 0.666748 23.3332 5.62394 23.3332 11.7571C23.3332 14.8477 22.2092 17.7169 20.3592 20.1488C18.3182 22.8314 15.8027 25.1687 12.9712 27.0033C12.3232 27.4273 11.7383 27.4593 11.0271 27.0033C8.17948 25.1687 5.66395 22.8314 3.6405 20.1488C1.78915 17.7169 0.666504 14.8477 0.666504 11.7571ZM8.25911 12.1024C8.25911 14.157 9.93569 15.7729 11.9914 15.7729C14.0485 15.7729 15.7412 14.157 15.7412 12.1024C15.7412 10.0638 14.0485 8.36914 11.9914 8.36914C9.93569 8.36914 8.25911 10.0638 8.25911 12.1024Z"
                    fill="white"
                  />
                </g>
              </svg>
              Address
            </h2>
            {/* <p>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.
            </p> */}
            <p style={{ color: "white", fontWeight: "600" }}>
              IInd Floor, 2393/222, CHHATTA SHAHJI, <br />
              CHAWRI BAZAR, New Delhi,
              <br />
              Delhi, India - 110006
            </p>
          </span>
          <div className="contact-socials">
            <Link to={"https://www.instagram.com/neurobridge.tech"}>
              <FaInstagram />
            </Link>
            <Link
              to={
                "https://twitter.com/i/flow/login?redirect_after_login=%2FNeuroBridgeTech"
              }
            >
              <FaXTwitter />
            </Link>

            <Link to={"https://www.linkedin.com/company/neurobridge-tech"}>
              <FaLinkedin />
            </Link>
            <Link to={"mailto:contact@neurobridge.tech"}>
              <SiGmail />
            </Link>
          </div>
        </section>
        <ContactForm />
      </div>
    </div>
  );
};
