import React from "react";
import "../../../styles/globalComponents/Navbar/NavMenu.css";

const NavMenu = ({ navBtns }) => {
  return (
    <div className="nav-menu-main-div">
      <div className="menu-content">
        <div className="nav-options">
          {navBtns.map((item, index) => (
            <a href={item.path} key={index} className="nav-item">
              {item.name}
            </a>
          ))}
          <a href="https://chat.aichanakya.in/" className="nav-item">
            Try <span style={{ color: "#FF4326" }}>Chanakya</span>
          </a>
          <a href="https://playground.neurobridge.tech/" className="nav-item">
            Try Playground
          </a>
        </div>

        {/* <div className='user-btns'>
                <button className='log-in-btn'>
                    Log In
                </button>
                <button className='sign-in-btn'>
                    Sign Up
                </button>
            </div> */}
      </div>
    </div>
  );
};

export default NavMenu;
