import React from "react";
import "../../../styles/Home/Pricing.css";
//import ToggleButton from "./ToggleBtn/ToggleButton";
//import MonthlyPricing from "./MonthlyPricing";
import AnnualPricing from "./AnnualPricing";
import img1 from "../../../assets/pricing/Group 95.webp";
import img2 from "../../../assets/pricing/Group 96.webp";
import img3 from "../../../assets/pricing/Group 98.webp";
import img4 from "../../../assets/pricing/Group 99.webp";
import img5 from "../../../assets/pricing/Group.webp";
import { useMobile } from "../../../globalComponents/isMobileContext";
import { Helmet } from "react-helmet";
const Pricing = () => {
  const { isMobile } = useMobile();
  /*  const [isMonthly, setIsMonthly] = useState(true);
  const pricingButton = [
    { id: 0, name: "TTS" },
    { id: 1, name: "ASR" },
    { id: 2, name: "LLMs" },
    { id: 3, name: "VectorDB Storage" },
  ];
  
  const toggleSwitchHandler = () => {
    setIsMonthly(!isMonthly);
  };*/

  return (
    <div style={{ marginTop: "14rem" }}>
      <Helmet>
        <title>
          Generative AI Chatbot Solutions at Best Prices: Neurobridge Minsky
        </title>
        <meta
          name="description"
          content="Neurobridge Minsky Offers Top-notch Generative AI Chatbot Solutions at the Best Prices. Transform Your Customer Engagement With Cutting-edge Technology. Visit Now!"
        />
      </Helmet>
      {!isMobile && (
        <span className="pricing-top-heading">
          NeuroBridge Pricing Models 2024
        </span>
      )}
      <div className="pricing-top-section">
        <img src={img1} alt="img1" className="pricing-img1 fade-in" />
        <img src={img2} alt="img2" className="pricing-img2 fade-in" />
        <img src={img3} alt="img3" className="pricing-img3 fade-in" />
        <img src={img4} alt="img4" className="pricing-img4 fade-in" />
        <img src={img5} alt="img5" className="pricing-img5 fade-in" />
        <p className="sub-heading">
          Pricing Plan for All of your <br /> Comprehensive
          <span>
            {" "}
            Artificial <br />
            Intelligence
          </span>{" "}
          Needs
        </p>

        {/* <ToggleButton onClick={toggleSwitchHandler} active={!isMonthly} /> */}
        {/*<div className='pricing-btn'>
          {pricingButton.map((d, index) => (
            <button key={index}>{d.name}</button>
          ))}
        </div> */}
      </div>
      <div>
        <AnnualPricing />
      </div>
    </div>
  );
};

export default Pricing;
