import React, { useRef } from "react";
import "../../styles/career.css";
import { Helmet } from "react-helmet";
import joinOurTeam from "../../assets/Career/joinOurTeam.webp";

import { FaArrowDown } from "react-icons/fa6";
import JobBoard from "./JobOpening";

const Career = () => {
  const jobsRef = useRef(null);

  const handleScrollToJobs = () => {
    if (jobsRef.current) {
      jobsRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  return (
    <>
      <Helmet>
        <title>
          Exploring the World of AI Through Informative Blogs: Neurobridge
          Minsky
        </title>
        <meta
          name="description"
          content="Discover a World of Informative Blogs on AI and Chat With Our Generative Chatbot, Neurobridge Minsky. Stay Informed and Up-to-date on the Latest Advancements in Artificial Intelligence. Visit Now!"
        />
      </Helmet>
      <div className="career">
        <div className="join-team">
          <h2>Join the team </h2>
          <h4>
            Neurobridge is an employee-centred company that looks after every
            employee, gives autonomy to make choices, supports self-development
            and career growth. Our development team is always in search of
            talented individuals to join our employee-centred culture.
            <br />
            <br />
            Navigate below to see our current open positions!
          </h4>
          <button onClick={handleScrollToJobs}>
            Open positions <FaArrowDown style={{ alignSelf: "center" }} />
          </button>
        </div>
        <img src={joinOurTeam} alt="" />
      </div>
      <div className="jobs" ref={jobsRef}>
        <JobBoard />
      </div>
    </>
  );
};

export default Career;
