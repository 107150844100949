import React from "react";
import accuracyIcon from "../../assets/icons/editIcon.svg";
import insightsIcon from "../../assets/icons/insightsicon.svg";
import engagementIcon from "../../assets/icons/engagementIcon.svg";
import "../../styles/Home/components/OurNumbers.css";
import CountUp from "react-countup";
import { useInView } from "react-intersection-observer";

const NumberItem = ({ icon, label, value, para }) => {
  const [ref, inView] = useInView({ triggerOnce: false });

  return (
    <div ref={ref} className="briefing-div">
      <div className="brief">
        <img src={icon} className="brief-icon" alt={label} />
        <p className="brief-label">{label}</p>
        {/* <p className='brief-para'>
                    {inView ? (
                        <CountUp end={value} />
                    ) : 0}%
                </p> */}
        {inView && <p className="brief-para">{para}</p>}
      </div>
    </div>
  );
};

const OurNumbers = () => {
  const [ref, inView] = useInView({ triggerOnce: false });

  const numbers = [
    {
      id: 0,
      icon: accuracyIcon,
      label: "ACCURATE",
      value: 85,
      para: "Minsky AI accurately understands user queries or commands for precise responses and actions.",
    },
    {
      id: 1,
      icon: insightsIcon,
      label: "COST SAVING",
      value: 60,
      para: "Automating customer interactions saves companies on labour costs by reducing the need for human intervention.",
    },
    {
      id: 2,
      icon: engagementIcon,
      label: "TECHNICAL EFFICIENCY",
      value: 40,
      para: "Minsky AI understands and generates interactions that feel human-like, engaging users naturally.",
    },
  ];

  return (
    <div className="our-numbers-main-div">
      <div className="numbers-main-content">
        <div ref={ref} className="top-numbers">
          {numbers.map((item, index) => (
            <div key={index} className="numbers-div">
              <div className="main-numbers">
                <p className="num-value">
                  {inView ? <CountUp end={item.value} /> : 0} %
                </p>
                <h className="num-label">{item.label}</h>
              </div>
            </div>
          ))}
        </div>
        <div className="bottom-numbers">
          {numbers.map((item, index) => (
            <NumberItem
              key={index}
              icon={item.icon}
              label={item.label}
              para={item.para}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurNumbers;
