import React from "react";
import shieldDone from "../../assets/icons/shield-done.svg";
import "../../styles/Home/components/UniqueSection.css";

const UniqueSection = () => {
  const text =
    "Industries across the world are seeking innovative solutions to streamline operations, optimise processes, and drive growth.";

  const cardData = [
    {
      title: "Intelligent Data Segmentation",
      content: "Simplify complex data into clear, actionable pieces.",
    },
    {
      title: "Contextual Search Precision",
      content:
        "Find exactly what you need with searches that understand context leverage deep insights from your data.",
    },
    {
      title: "Customizable Processing",
      content: "Adjust our platform to meet your specific business needs.",
    },
    {
      title: "Domain-Specific Expertise",
      content:
        "Get expert assistance in fields like coding, legal, HR, and marketing.",
    },
    {
      title: "Versatile Format Support",
      content:
        "Work with various data types, from texts and videos to codes and images.",
    },
    {
      title: "Market and Language Adaptation",
      content:
        "Our tools respect cultural and linguistic differences to deliver relevant results.",
    },
  ];

  return (
    <div className="unique-section-main-div">
      <div className="header-main">
        <h1 className="sub-heading">PERSONALISED SOLUTIONS</h1>
        <p className="main-heading">
          Discover Capabilities for <br />
          <span>Minsky</span> Platform
        </p>
        <p className="text-paragraph">{text}</p>
      </div>

      {/* <div className='unique-section-book-btn'>
        <p className='unique-section-btn-title'>CTA- Try Minsky AI Today</p>
        <ScheduleBtn />
      </div> */}

      <div className="unique-card-container">
        {cardData.map((item, index) => (
          <div key={index} className="unique-card">
            <div className="heading-div">
              <img src={shieldDone} className="shield-icon" alt="shield" />
              <p className="main-card-heading">{item.title}</p>
            </div>
            <p className="main-card-text">{item.content}</p>
            {/* <div className='view-button'>
              View TTS Plan <FaArrowRight />
            </div> */}
          </div>
        ))}
      </div>
    </div>
  );
};

export default UniqueSection;
