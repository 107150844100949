import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import NavBar from "./globalComponents/Navbar/NavBar";
import Footer from "./globalComponents/Footer/Footer";
// import mainBackground from './assets/background/appBackground.webp';
// import InnerBackground from './assets/background/topBackground.webp';
import Pricing from "./components/Home/Pricing/Pricing";
import { ContactPage } from "./components/Contact/ContactPage";
import { AboutUsPage } from "./components/AboutUs/AboutUsPage";
import Blogs from "./components/Blogs/Blogs";
import Products from "./components/Products/Products";
import Discovering from "./components/Blogs/Discovering";
import Effective from "./components/Blogs/Effective";
import TheRealGameChanger from "./components/Blogs/real-game-changer";
import Finding from "./components/Blogs/Finding";
import Enhancing from "./components/Blogs/Enhancing";
import EnhancingDecision from "./components/Blogs/enhancing-decision-making";
import TheImpact from "./components/Blogs/TheImact";
import Streamline from "./components/Blogs/Streamline";
import Smoother from "./components/Blogs/Smoother";
import Exploring from "./components/Blogs/Exploring";
import CaptivatingAudiences from "./components/Blogs/CaptivatingAudiences";
import ConvenientSolutions from "./components/Blogs/ConvenientSolutions";
import FromWordsToSound from "./components/Blogs/FromWordsToSound";
import RevolutionisingCommunication from "./components/Blogs/RevolutionisingCommunication";
import StreamliningOperationsInCorporating from "./components/Blogs/StreamliningOperationsIncorporatingAI";
import TheUltimateAIAudio from "./components/Blogs/TheUltimateAIAudio";
import Career from "./components/Career/Career";
import BestAI from "./components/Blogs/best-ai-website-for-all-overall-assistance";
import BestConversational from "./components/Blogs/best-conversational-ai-website";
import BestAiChatbotChanakya from "./components/Blogs/ChanakyaStandOut";
import BestChatGPTAlternative from "./components/Blogs/best-chatgpt-alternative-website-chanakya";
import BestAiReinforcement from "./components/Blogs/best-ai-reinforcement-learning-in-machine-learning-chanakya";
import BestTextGenerator from "./components/Blogs/best-artificial-intelligence-ai-text-generator-chanakya";
import ChanakyaInsightsonArtificialNeural from "./components/Blogs/Chanakya-Insights-on-Artificial-Neural";
import YourFriendlyGenerativeAiChatbot from "./components/Blogs/your-friendly-generative-ai-chatbot";
import AdvancedArtificialIntelligenceGeneratorForInnovativeSolutions from "./components/Blogs/advanced-artificial-intelligence-generator-for-innovative-solutions";
import HowAIChatbotsTransformBusinessCommunication from "./components/Blogs/how-aI-chatbots-transform-business-communication";
import ChanakyaOnGenerativeAIChatbotsInsightsStrategies from "./components/Blogs/chanakya-on-generative-ai-chatbots-insights-strategies";
function App() {
  // const backgroundImage =
  //   window.location.pathname === '/' ? mainBackground : InnerBackground;
  return (
    <div
      className="App"
      style={{
        // backgroundImage: `url(${backgroundImage})`,

        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <BrowserRouter>
        <NavBar />

        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/pricing" element={<Pricing />} />
          <Route path="/contact" element={<ContactPage />} />
          <Route path="/aboutus" element={<AboutUsPage />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/solutions" element={<Products />} />
          <Route path="/career" element={<Career />} />
          <Route
            path="/blogs/discovering-the-versatility-of-minsky-ai-in-real-world-applications"
            element={<Discovering />}
          />
          <Route
            path="/blogs/best-ai-website-for-all-overall-assistance"
            element={<BestAI />}
          />
          <Route
            path="/blogs/best-conversational-ai-website"
            element={<BestConversational />}
          />
          <Route
            path="/blogs/an-effective-revolutionary-method-for-conversational-ai"
            element={<Effective />}
          />
          <Route
            path="/blogs/the-real-game-changer"
            element={<TheRealGameChanger />}
          />
          <Route
            path="/blogs/finding-your-perfect-ai-audio-generator-a-step-by-step-guide"
            element={<Finding />}
          />
          <Route
            path="/blogs/enhancing-customer-experiences-the-role-of-ai-based-chatbots"
            element={<Enhancing />}
          />
          <Route
            path="blogs/enhancing-decision-making"
            element={<EnhancingDecision />}
          />
          <Route
            path="/blogs/the-impact-of-internal-bots-on-business"
            element={<TheImpact />}
          />
          <Route
            path="/blogs/streamline-customer-support-with-ai-powered-external-bots-with-minsky"
            element={<Streamline />}
          />
          <Route
            path="/blogs/smoother-operations-with-advanced-conversational-ai-simple-integration-strategies"
            element={<Smoother />}
          />
          <Route
            path="/blogs/exploring-the-future-with-neurobridge-a-next-generation-ai-model"
            element={<Exploring />}
          />
          <Route
            path="/blogs/captivating-audiences-the-power-of-ai-voiceover-in-storytelling"
            element={<CaptivatingAudiences />}
          />
          <Route
            path="/blogs/convenient-solutions-discovering-the-best-ai-voice-generator-platforms-online"
            element={<ConvenientSolutions />}
          />
          <Route
            path="/blogs/from-words-to-sound-choosing-the-perfect-text-to-speech-converter-for-your-projects"
            element={<FromWordsToSound />}
          />
          <Route
            path="/blogs/revolutionising-communication-how-ai-chatbots-are-reshaping-interaction"
            element={<RevolutionisingCommunication />}
          />
          <Route
            path="/blogs/streamlining-operations-incorporating-ai-chatbots-into-your-workflow"
            element={<StreamliningOperationsInCorporating />}
          />
          <Route
            path="/blogs/minsky-the-ultimate-ai-audio-generator"
            element={<TheUltimateAIAudio />}
          />
          <Route
            path="/blogs/best-ai-chatbot-chanakya"
            element={<BestAiChatbotChanakya />}
          />
          <Route
            path="/blogs/best-chatgpt-alternative-website-chanakya"
            element={<BestChatGPTAlternative />}
          />
          <Route
            path="/blogs/best-ai-reinforcement-learning-in-machine-learning-chanakya"
            element={<BestAiReinforcement />}
          />
          <Route
            path="/blogs/best-artificial-intelligence-ai-text-generator-chanakya"
            element={<BestTextGenerator />}
          />
          <Route
            path="/blogs/chanakya-insights-on-artificial-neural-networks-expert-analysis-trends"
            element={<ChanakyaInsightsonArtificialNeural />}
          />
          <Route
            path="/blogs/your-friendly-generative-ai-chatbot"
            element={<YourFriendlyGenerativeAiChatbot />}
          />
          <Route
            path="/blogs/advanced-artificial-intelligence-generator-for-innovative-solutions"
            element={
              <AdvancedArtificialIntelligenceGeneratorForInnovativeSolutions />
            }
          />
          <Route
            path="/blogs/how-ai-chatbots-transform-business-communication"
            element={<HowAIChatbotsTransformBusinessCommunication />}
          />
          <Route
            path="/blogs/chanakya-on-generative-ai-chatbots-insights-strategies"
            element={<ChanakyaOnGenerativeAIChatbotsInsightsStrategies />}
          />
          <Route path="/*" element={<h1>No Route Found</h1>} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
