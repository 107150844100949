import React, { useState } from "react";
import { IoClose, IoMenu } from "react-icons/io5";
import MainLogo from "../mainLogo/MainLogo";
import "../../styles/globalComponents/Navbar/NavBar.css";
import NavMenu from "./component/NavMenu";
import { useMobile } from "../isMobileContext";
const NavBar = () => {
  const { isMobile } = useMobile();
  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false);

  const navBtns = [
    { id: 0, name: "Solutions", path: "/solutions" },
    { id: 1, name: "Blog", path: "/blogs" },
    { id: 2, name: "About Us", path: "/aboutus" },
    { id: 3, name: "Pricing", path: "/pricing" },
    { id: 4, name: "Contact", path: "/contact" },
    { id: 5, name: "Career", path: "/career" },
  ];

  return (
    <div
      style={isNavMenuOpen ? { position: "fixed", top: 0, left: 0 } : {}}
      className="navbar-main-container"
    >
      <div className="blur-bg"></div>

      {isNavMenuOpen && <NavMenu navBtns={navBtns} />}

      <div className="navbar-main-items">
        <div className="main-logo">
          <MainLogo />
        </div>
        <div className="nav-btn">
          <div className="nav-options">
            {navBtns.map((item, index) => (
              <div key={index} className="nav-item">
                <a
                  style={{ textDecoration: "none", color: "#fff" }}
                  href={item.path}
                >
                  {item.name}
                </a>
              </div>
            ))}
          </div>
          {!isMobile && (
            <a
              style={{ textDecoration: "none" }}
              href="https://chat.aichanakya.in/"
            >
              <div className="nav-optionsTry">
                Try <span style={{ color: "orange" }}> Chanakya</span>
              </div>
            </a>
          )}
          {!isMobile && (
            <a
              style={{ textDecoration: "none" }}
              href="https://playground.neurobridge.tech/"
            >
              <div className="nav-optionsTry">Try Playground</div>
            </a>
          )}
        </div>
        <div className="user-btns">
          {/*
          <button className="log-in-btn">Login</button>
                <button className='sign-in-btn'>
                    Sign Up
                </button> */}
          <button
            onClick={() => setIsNavMenuOpen((prev) => !prev)}
            className="menu-btn"
          >
            {isNavMenuOpen ? <IoClose /> : <IoMenu />}
          </button>
        </div>{" "}
      </div>
    </div>
  );
};

export default NavBar;
