import React from 'react';
import '../../styles/globalComponents/mainLogo/MainLogo.css';
import logo from '../../assets/logos/logo01.webp';

const MainLogo = () => {
  return (
    <div className='main-logo-div'>
      <div className='logo'>
        <a href='/'>
          <img src={logo} alt='logo' />
        </a>
      </div>
      {/* <p className='logo-text'>NEUROBRIDGE</p> */}
    </div>
  );
};

export default MainLogo;
