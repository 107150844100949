import React from 'react';
import '../../styles/AboutUs/AboutUs.css';
import atharvImg from '../../assets/aboutUs/atharv.webp';
import garvitImg from '../../assets/aboutUs/garvit.webp';
import { Link } from 'react-router-dom';
import { FaLinkedinIn } from 'react-icons/fa';

export const AboutOurTeam = () => {
  const dummy = [
    {
      img: garvitImg,
      name: 'Garvit Kothari',
      rank: 'Co-Founder and CEO',
      link: 'https://www.linkedin.com/in/garvit-k/',
    },
    {
      img: atharvImg,
      name: 'Atharv Garg',
      rank: 'Co Founder and COO',
      link: 'https://www.linkedin.com/in/atharvgarg/',
    },
    // {
    //     name:"Lewis Hamilton",
    //     rank:"CTO"
    // }
  ];

  // const dummySecond = [{
  //     name:"Carlos Sainz",
  //     rank:"UX Designer"
  // },
  // {
  //     name:"Charles Leclerc",
  //     rank:"Front End Dev"
  // },
  // {
  //     name:"Charles Leclerc",
  //     rank:"Back End Dev"
  // },
  // {
  //     name:"Lewis Hamilton",
  //     rank:"Animator"
  // }];
  return (
    <>
      <section className='contact-main-header' style={{ marginTop: '5rem' }}>
        {' '}
        <h2>Our Team</h2>
        <p>
          Meet Garvit Kothari and Atharv Garg, the dynamic duo behind
          Neurobridge Tech. Their journey from friends to AI innovators began
          during their master's program at the University of Birmingham. Fueled
          by a shared passion for AI and a vision to bridge the gap in data
          utilisation, they embarked on a mission to revolutionise the industry.
        </p>
      </section>
      <div className='ourTeam-section'>
        {dummy.map((item, index) => (
          <div key={index} className='ourteam-card'>
            <img src={item.img} alt='xyz' />
            <p className='card-name'>{item.name}</p>
            <p className='card-rank'>{item.rank}</p>
            <Link
              to={item.link}
              style={{ textDecoration: 'none', color: 'white' }}
            >
              <FaLinkedinIn style={{ fontSize: '1.5rem' }} />
            </Link>
          </div>
        ))}
      </div>
      {/* <div className='ourTeam-section'>
        {dummySecond.map((item,index)=>(
           <div key={index} className='ourteam-card'>
                <img src={dummyimg2} alt='xyz' />
                <p className='card-name' style={{fontSize:"1.8rem"}}>{item.name}</p>
                <p className='card-role'>{item.rank}</p>
           </div> 
        ))}

      </div> */}
    </>
  );
};
