import React, { useEffect, useState } from 'react';
import line from '../../assets/extras/Vector 287.webp';

export const TypingAnimation = ({ text }) => {
  const [displayText, setDisplayText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const typingTimer = setTimeout(() => {
      setDisplayText(text.substring(0, currentIndex + 1));
      setCurrentIndex(currentIndex + 1);
    }, 20);

    if (currentIndex === text.length) {
      clearTimeout(typingTimer);
    }

    return () => clearTimeout(typingTimer);
  }, [text, currentIndex]);

  return <>{displayText}</>;
};
const AboutUsMain = () => {
  const circleSvg1 = (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      className='circleSvg1'
    >
      <circle
        cx='15'
        cy='15'
        r='11.5'
        fill='white'
        stroke='#E25858'
        stroke-width='7'
      />
    </svg>
  );
  const circleSvg2 = (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      className='circleSvg2'
    >
      <circle
        cx='15'
        cy='15'
        r='11.5'
        fill='white'
        stroke='#E25858'
        stroke-width='7'
      />
    </svg>
  );
  const circleSvg3 = (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='30'
      height='30'
      viewBox='0 0 30 30'
      fill='none'
      className='circleSvg3'
    >
      <circle
        cx='15'
        cy='15'
        r='11.5'
        fill='white'
        stroke='#E25858'
        stroke-width='7'
      />
    </svg>
  );

  return (
    <div className='aboutUs-main-Container'>
      <img src={line} alt='line' className='line' />
      {circleSvg1}
      {circleSvg2}
      {circleSvg3}
      <section className='aboutus-part-one'>
        <h2>Our Mission</h2>
        <p>
          <TypingAnimation text="Our Mission is to make AI accessible, actionable, and advantageous for businesses everywhere. With FineTuning and RAG as a Service, we're tearing down the complexity of AI, making it easy for companies to harness their data in ways they never thought possible. Think of it as giving businesses a pair of AI-powered glasses to see their data in 4K clarity, uncovering insights that drive smarter decisions." />
          <br />
          <br />
        </p>
        {/* <div className='aboutus-calender'>
          <FaCalendarDays /> View TTS Plan
        </div> */}
      </section>
      <section className='aboutus-part-two'>
        <h2>Who are We</h2>
        <p>
          <TypingAnimation text='Neurobridge Tech is a team of passionate innovators and experts in artificial intelligence technology. With a collective mission to drive positive change through technology, we bring together diverse skills and expertise to create cutting-edge AI solutions tailored to meet the unique needs of our clients.' />
        </p>
      </section>
      <section className='aboutus-part-three'>
        <h2>Why Choose Us</h2>
        <p>
          <TypingAnimation text='With seasoned professionals, we offer innovative AI solutions tailored to your needs. We stay ahead in technology, provide customizable options, and ensure dedicated support from start to success, all while delivering tangible results for your business.' />
        </p>
      </section>
    </div>
  );
};

export default AboutUsMain;
