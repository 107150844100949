import React from 'react'
import calenderIcon from "../../assets/icons/calenderIcon.svg"
import "../../styles/globalComponents/ScheduleBtn.css"
import { useNavigate } from 'react-router-dom';


const ScheduleBtn = ({ onClickHandler }) => {
  const navigate = useNavigate()

const handleClick = () =>{
  navigate("/contact")
}



  return (
      <>
      <button onClick={handleClick} className='schedule-main-button'>
        <img src={calenderIcon} className='schedule-icon' alt='calender' />
        Book Your Demo
    </button>
    </>
  );
};

export default ScheduleBtn