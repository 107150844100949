import React from "react";
import { AboutUsHeader } from "./AboutUsHeader";
import AboutUsMain from "./AboutUsMain";
import { AboutOurTeam } from "./AboutOurTeam";
import { Helmet } from "react-helmet";

export const AboutUsPage = () => {
  return (
    <>
      <Helmet>
        <title>
          About Us Generative AI Chatbot - Revolutionizing AI Technology:
          Neurobridge Minsky
        </title>
        <meta
          name="description"
          content="Neurobridge Minsky Is Revolutionizing AI Technology With Our Generative AI Chatbot. Learn More About Us and How We Are Changing the Game in Artificial Intelligence. Visit Now!"
        />
      </Helmet>
      <AboutUsHeader />
      <AboutUsMain />
      <AboutOurTeam />
    </>
  );
};
