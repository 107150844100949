import React from "react";

import "../../styles/Home/components/DiscoverCards.css";
import img1 from "../../assets/picture/img1.webp";
import img2 from "../../assets/picture/img2.webp";
import img3 from "../../assets/picture/img3.webp";
import img4 from "../../assets/picture/img4.webp";
import img5 from "../../assets/picture/img5.webp";
import img6 from "../../assets/picture/img6.webp";
import img7 from "../../assets/picture/img7.webp";
// import img8 from '../../assets/picture/';

const DiscoverCards = () => {
  return (
    <div className="discover-cards-main-div">
      <p className="discover-sub-title">
        <span>TAILORED SOLUTIONS</span> FOR YOUR UNIQUE SYSTEM
      </p>
      <p className="discover-title">
        Empowering Efficiency
        <br />
        Neurobridge Tech's{" "}
        <span>
          AI <br /> Use Cases
        </span>
      </p>
      <div style={{ position: "relative" }}>
        {/* <img
          src={cardGrid}
          className='card-grid-image'
          alt='card-grid'
          style={{ position: 'relative', top: '0', left: '0' }}
        /> */}
        <div
          className="grid-container"
          style={{
            position: "relative",
            top: "10rem",
            paddingLeft: "2.5rem",
            left: "0",
            width: "100%",
            height: "800px",
            marginTop: "3rem",
          }}
        >
          <div className="grid-item">
            {" "}
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "center",
              }}
            >
              <li>Invoice Generation</li>
              <li>Taxation Information</li>
              <li>Financial Reporting</li>
            </ul>
            <div
              className="image-overlay"
              style={{ backgroundImage: `url(${img1})` }}
            >
              {" "}
              <div>
                <span style={{ color: "#9b59e3" }}>ACCOUNTING</span>
                <br /> TASKS
              </div>
            </div>
          </div>
          <div className="grid-item">
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "center",
              }}
            >
              <li>Investment Recommendations</li>
              <li>Portfolio Management</li>
              <li>Budgeting Assistance</li>
              <li>Personalized Financial Advice</li>
            </ul>
            <div
              className="image-overlay"
              style={{ backgroundImage: `url(${img2})` }}
            >
              <div>
                <span style={{ color: "#e35958" }}>FINANCE</span>
                <br /> DEPARTMENT
              </div>
            </div>
          </div>
          <div className="grid-item">
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "center",
              }}
            >
              <li>Information Retrieval</li>
              <li>Data Entry and Management</li>
              <li>Database Querying</li>
            </ul>
            <div
              className="image-overlay"
              style={{ backgroundImage: `url(${img3})` }}
            >
              <div>
                <span style={{ color: "#e35958" }}>DATABASE</span>
                <br /> CREATION
              </div>
            </div>
          </div>
          <div className="grid-item">
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "center",
              }}
            >
              <li>Account Inquiries</li>
              <li>Loan Applications and Approvals</li>
            </ul>
            <div
              className="image-overlay"
              style={{ backgroundImage: `url(${img4})` }}
            >
              <div>
                <span style={{ color: "#9b59e3" }}>BANKING</span>
                <br /> INDUSTRY
              </div>
            </div>
          </div>
          <div className="grid-item">
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "center",
              }}
            >
              <li>Appointment Scheduling</li>
              <li>Symptom Assessment and Triage</li>
              <li>Medication Reminders</li>
              <li>Health Monitoring and Tracking</li>
            </ul>
            <div
              className="image-overlay"
              style={{ backgroundImage: `url(${img5})` }}
            >
              <div>
                <span style={{ color: "#9b59e3" }}>CUSTOMER</span>
                <br /> SUPPORT
              </div>
            </div>
          </div>
          <div className="grid-item">
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "center",
              }}
            >
              <li>Narration for Audio Content</li>
              <li>Voice Assistance</li>
              <li>Language Localisation</li>
            </ul>
            <div
              className="image-overlay"
              style={{ backgroundImage: `url(${img6})` }}
            >
              <div>
                <span style={{ color: "#e35958" }}>HEALTHCARE</span>
                <br /> INDUSTRY
              </div>
            </div>
          </div>
          <div className="grid-item">
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "center",
              }}
            >
              <li>Legal Document Drafting</li>
              <li>Contract Review</li>
              <li>Compliance Assistance</li>
              <li>Legal Research Support</li>
            </ul>
            <div
              className="image-overlay"
              style={{ backgroundImage: `url(${img7})` }}
            >
              <div>
                <span style={{ color: "#e35958" }}>LEGAL</span>
                <br /> ISSUES
              </div>
            </div>
          </div>
          <div className="grid-item">
            <ul
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "center",
              }}
            >
              <li>Market Research</li>
              <li>Review and Feedback</li>
              <li>Issue Resolution and Escalation</li>
            </ul>
            <div className="image-overlay">
              <div>
                <span style={{ color: "#9b59e3" }}>VOICEOVER &</span>
                <br /> CONVERSATIONAL <br /> AI
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiscoverCards;
